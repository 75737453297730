import { Object3D, Vector3, PMREMGenerator, Texture, Scene } from "three";
import { Sky } from "three/examples/jsm/objects/Sky.js";

import { app } from "../../client";

class OuterScene extends Object3D {
  private sky: Sky;
  public sunPosition: Vector3;
  public environment: Texture;

  constructor() {
    super();

    this.sky = new Sky();
    this.sky.scale.setScalar(10000);
    this.add(this.sky);

    const parameters = {
      inclination: 0.4,
      azimuth: 0.2471,
    };

    const theta = Math.PI * (parameters.inclination - 0.5);
    const phi = 2 * Math.PI * (parameters.azimuth - 0.5);

    this.sunPosition = new Vector3();
    this.sunPosition.x = Math.cos(phi);
    this.sunPosition.y = Math.sin(phi) * Math.sin(theta);
    this.sunPosition.z = Math.sin(phi) * Math.cos(theta);

    this.sky.material.uniforms["turbidity"].value = 1.5;
    this.sky.material.uniforms["rayleigh"].value = 0.35;
    this.sky.material.uniforms["mieCoefficient"].value = 0.001;
    this.sky.material.uniforms["mieDirectionalG"].value = 0.999;
    this.sky.material.uniforms["mieDirectionalG"].value = 0.999;
    this.sky.material.uniforms["sunPosition"].value.copy(this.sunPosition);
    // this.sky.material.uniforms["exposure"].value = 0.67;

    const pmremGenerator = new PMREMGenerator(app.webGlView.renderer);
    this.environment = pmremGenerator.fromScene(
      (this.sky as unknown) as Scene,
    ).texture;
    pmremGenerator.dispose();
  }
}

export default OuterScene;
