import { throttle as _throttle } from "lodash";

import {
  IUpdateSubtitleEvent,
  IToggleBuyBannerEvent,
  IHideViewfinderEvent,
  UIEvents,
  IToggleVideoControlsEvent,
  IToggleSkipToEvent,
} from "../../util/Events";

import Subtitles from "../components/Subtitles";
import Viewfinder from "../components/Viewfinder";
import MuteButton from "../components/MuteButton";
import FullscreenButton from "../components/FullscreenButton";
import ShareButton from "../components/ShareButton";
import CreditsButton from "../components/CreditsButton";
import PlayPauseButton from "../components/PlayPauseButton";
import PopIns from "../components/PopIns";

export default class HUDView {
  private el: HTMLElement;
  private contentEl: HTMLElement;
  private viewfinder: Viewfinder;
  private subtitles: Subtitles;
  private popIns: PopIns;
  private hideScrollTO: any;
  private showScrollTO: any;
  private showContentTO: any;
  private isShowingVideoControls = false;
  private isScrolling = false;

  constructor() {
    this.el = document.querySelector("._js_hud-view");
    this.contentEl = this.el.querySelector("._js_hud-view__content");
    this.onSubtitleUpdated = this.onSubtitleUpdated.bind(this);
    this.onHideViewfinder = this.onHideViewfinder.bind(this);
    this.onToggleBuyBanner = this.onToggleBuyBanner.bind(this);
    this.onToggleSkip = this.onToggleSkip.bind(this);
    this.onToggleVideoControls = this.onToggleVideoControls.bind(this);
    this.onMouseMoved = this.onMouseMoved.bind(this);

    this.viewfinder = new Viewfinder(document.querySelector("._js_viewfinder"));
    this.subtitles = new Subtitles(document.querySelector("._js_subtitles"));
    new MuteButton(document.querySelector("._js_mute-button"));
    new FullscreenButton(document.querySelector("._js_fullscreen-button"));
    new ShareButton(document.querySelector("._js_share"));
    new CreditsButton(document.querySelector("._js_credits-button"));
    new PlayPauseButton(document.querySelector("._js_play-pause"));

    document.querySelector("._js_skip").addEventListener("click", () => {
      window.dispatchEvent(
        new CustomEvent(UIEvents.GoToFrame, {
          detail: window.innerWidth > window.innerHeight ? 2090 : 1976,
        }),
      );
    });
    this.popIns = new PopIns(document.querySelector("._js_pop-ins"));

    this.onScrolled = this.onScrolled.bind(this);

    window.addEventListener(UIEvents.UpdateSubtitle, this.onSubtitleUpdated);
    window.addEventListener(UIEvents.HideViewfinder, this.onHideViewfinder);
    window.addEventListener(UIEvents.ToggleCredits, this.onHideViewfinder);
    window.addEventListener(UIEvents.ToggleBuyBanner, this.onToggleBuyBanner);
    window.addEventListener(UIEvents.VideoControls, this.onToggleVideoControls);
    window.addEventListener(UIEvents.ToggleSkipTo, this.onToggleSkip);

    this.isVisible = true;

    this.showScrollTO = setTimeout(() => {
      this.popIns.isScrollVisible = true;
      setTimeout(() => {
        window.addEventListener("touchmove", _throttle(this.onScrolled, 333));
        window.addEventListener("wheel", _throttle(this.onScrolled, 333));
      }, 666);
    }, 333);

    window.addEventListener("touchmove", _throttle(this.onMouseMoved, 333));
    window.addEventListener("mousemove", _throttle(this.onMouseMoved, 333));
  }

  public set isVisible(isVisible: boolean) {
    if (isVisible) {
      this.el.style.display = "block";
      this.viewfinder.isActive = true;
    } else {
      this.el.style.display = "none";
      this.viewfinder.isActive = false;
    }
  }

  private onScrolled() {
    clearTimeout(this.hideScrollTO);
    clearTimeout(this.showScrollTO);
    clearTimeout(this.showContentTO);
    this.isScrolling = true;

    this.contentEl.classList.add("hud-view__content--is-hidden");
    this.hideScrollTO = setTimeout(() => {
      this.isScrolling = false;
      this.popIns.isScrollVisible = false;
    }, 222);
    this.showScrollTO = setTimeout(() => {
      this.popIns.isScrollVisible = true;
    }, 4444);

    if (!this.isShowingVideoControls) {
      this.showContentTO = setTimeout(() => {
        this.contentEl.classList.remove("hud-view__content--is-hidden");
      }, 1555);
    }
  }

  private onMouseMoved() {
    document.body.classList.remove("hide-pointer");
    if (this.isShowingVideoControls) {
      clearTimeout(this.showContentTO);
      if (!this.isScrolling) {
        this.contentEl.classList.remove("hud-view__content--is-hidden");
      }

      this.showContentTO = setTimeout(() => {
        document.body.classList.add("hide-pointer");
        this.contentEl.classList.add("hud-view__content--is-hidden");
      }, 2222);
    }
  }

  private onSubtitleUpdated({ detail }: IUpdateSubtitleEvent) {
    this.subtitles.subtitle = detail || null;
  }

  private onToggleBuyBanner({ detail }: IToggleBuyBannerEvent) {
    this.popIns.isBuyVisible = !!detail;
  }

  private onToggleSkip({ detail }: IToggleSkipToEvent) {
    this.popIns.isSkipVisible = !!detail;
  }

  private onToggleVideoControls({ detail }: IToggleVideoControlsEvent) {
    const { isVisible } = detail;
    if (isVisible !== undefined) {
      this.isShowingVideoControls = isVisible;
      this.popIns.isPlayPauseVisible = isVisible;
    }
    if (isVisible === true) {
      clearTimeout(this.showContentTO);
      this.showContentTO = setTimeout(() => {
        documebt.body.classList.add("hide-pointer");
        this.contentEl.classList.add("hud-view__content--is-hidden");
      }, 2222);
    }
  }

  private onHideViewfinder({ detail }: IHideViewfinderEvent) {
    this.viewfinder.isActive = !detail;
  }
}
