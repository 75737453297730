import monkeyPatch from "./lib/monkeyPatch";
import noise from "./lib/noise";
import {
  ShaderChunk,
  ShaderLib,
  ShaderMaterial,
  DoubleSide,
  Color,
  Texture,
} from "three";

class FlagMaterial extends ShaderMaterial {
  constructor({
    size,
    resolution,
    map,
  }: {
    size: number;
    resolution: number;
    map: Texture;
  }) {
    const amplitude = size * 0.2;
    const uniforms = {
      ...ShaderLib.phong.uniforms,
      amplitude: {
        value: amplitude,
      },
      speed: { value: 0.7 + Math.random() * 0.3 },
      frequency: { value: 0.2 + Math.random() * 0 },
      noiseOffset: { value: Math.random() * 100 },
      time: { value: 0 },
      offset: { value: size / resolution },
    };

    super({
      lights: true,
      wireframe: false,
      side: DoubleSide,
      uniforms: { ...uniforms, map: { value: map } },
    });

    this.map = map;

    const patchedVertexShader = monkeyPatch(ShaderChunk.meshphong_vert, {
      header: `
        uniform float time;
        uniform float amplitude;
        uniform float speed;
        uniform float frequency;
        uniform float noiseOffset;
        uniform float offset;
        varying vec4 debugColor;
  
        ${noise()}

        float map(float value, float min1, float max1, float min2, float max2) {
          return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
        }
        
        // the function which defines the displacement
        float displace(vec3 point) {
          return map(snoise(vec3(point.x * frequency, point.z * frequency, (time + noiseOffset) * speed)), -1.0, 1.0, 0.0, 1.0) * amplitude;
        }
        
        // http://lolengine.net/blog/2013/09/21/picking-orthogonal-vector-combing-coconuts
        vec3 orthogonal(vec3 v) {
          return normalize(abs(v.x) > abs(v.z) ? vec3(-v.y, v.x, 0.0)
          : vec3(0.0, -v.z, v.y));
        }
      `,
      // adapted from http://tonfilm.blogspot.com/2007/01/calculate-normals-in-shader.html
      main: `
        vec3 displacedPosition = position + normal * displace(position) * uv.y;
        debugColor = vec4(uv.xy, 0, 1.0);
  
        vec3 tangent = orthogonal(normal);
        vec3 bitangent = normalize(cross(normal, tangent));
        vec3 neighbour1 = position + tangent * offset;
        vec3 neighbour2 = position + bitangent * offset;
        vec3 displacedNeighbour1 = neighbour1 + normal * displace(neighbour1) * uv.y;
        vec3 displacedNeighbour2 = neighbour2 + normal * displace(neighbour2) * uv.y;
  
        // https://i.ya-webdesign.com/images/vector-normals-tangent-16.png
        vec3 displacedTangent = displacedNeighbour1 - displacedPosition;
        vec3 displacedBitangent = displacedNeighbour2 - displacedPosition;
  
        // https://upload.wikimedia.org/wikipedia/commons/d/d2/Right_hand_rule_cross_product.svg
        vec3 displacedNormal = normalize(cross(displacedTangent, displacedBitangent));
      `,

      "#include <defaultnormal_vertex>": ShaderChunk.defaultnormal_vertex.replace(
        // transformedNormal will be used in the lighting calculations
        "vec3 transformedNormal = objectNormal;",
        `vec3 transformedNormal = displacedNormal;`,
      ),

      // transformed is the output position
      "#include <displacementmap_vertex>": `
        transformed = displacedPosition;
      `,
    });

    this.vertexShader = patchedVertexShader;
    this.fragmentShader = ShaderChunk.meshphong_frag;
    // this.fragmentShader = `
    //   varying vec4 debugColor;

    //   void main() {
    //     gl_FragColor = debugColor;
    //   }
    // `;
  }
}

export default FlagMaterial;
