import Hls from "hls.js";
import iOSVersion from "ios-version";
import MREurosApp from "../client";

class VideoHLS {
  private videoElement: HTMLVideoElement;
  private hls?: Hls;

  constructor(videoElement: HTMLVideoElement) {
    this.videoElement = videoElement;
    this.videoElement.crossOrigin = "anonymous";
    this.videoElement.kill = this.kill.bind(this);
    this.init();
  }

  protected get src(): string {
    const iOS = iOSVersion(navigator.userAgent);
    if (!!iOS && iOS.major === 14 && (iOS.minor === 1 || iOS.minor === 3))
      return `https://stream.mux.com/${this.videoElement.dataset.playbackId}/medium.mp4`; // ios 14.1 and 14.3 have a bug with HLS video used as webgl textures
    if (this.videoElement.dataset.playbackId)
      return `https://stream.mux.com/${this.videoElement.dataset.playbackId}.m3u8`;
    return null;
  }

  private init() {
    if (MREurosApp.videoSupport === "hls" && this.src.indexOf(".mp4") === -1) {
      this.hls = new Hls();
      this.hls.loadSource(this.src);
      this.hls.attachMedia(this.videoElement);
    } else {
      this.videoElement.src = this.src;
    }
  }

  public kill(): void {
    this.videoElement.pause();
    this.hls?.detachMedia();
    this.hls?.destroy();
  }
}

export default VideoHLS;
