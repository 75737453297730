export default class LoadingBar {
  private el: HTMLDivElement;

  constructor(el: HTMLDivElement) {
    this.el = el;
  }

  public set isVisible(value: boolean) {
    if (value) {
      this.el.style.display = "block";
    } else {
      this.el.style.display = "none";
    }
  }

  public kill(): void {
    requestAnimationFrame(() => {
      this.el.style.display = "none";
    });
  }
}
