import {
  Scene,
  PerspectiveCamera,
  AmbientLight,
  DirectionalLight,
  AxesHelper,
  MeshBasicMaterial,
  Mesh,
  Object3D,
  TextureLoader,
  Vector3,
  DoubleSide,
  Box3,
  MeshLambertMaterial,
} from "three";
import AudioController from "./AudioController";
import CameraController from "./CameraController";
import OuterScene from "./OuterScene";
import Ghosts from "./Ghosts";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { BasisTextureLoader } from "../lib/BasisTextureLoader.js";
import MREurosApp, { app } from "../../client";
import { AppEvents, UIEvents } from "../util/Events";
import SimplexNoise from "simplex-noise";
import MainVideo from "./MainVideo";
import Pond from "./Pond";
import Confetti from "./Confetti";

const noiseX = new SimplexNoise("x");
const noiseY = new SimplexNoise("y");
const noiseZ = new SimplexNoise("z");

import mapsHI from "../../maps/HI/*.{jpg,basis}";
import mapsMED from "../../maps/MED/*.{jpg,basis}";
import mapsLO from "../../maps/LO/*.{jpg,basis}";
import modelSrc from "../../models/MR_LL_04_Demo_99.glb";
import waterNormalsSrc from "../../maps/waternormals.jpg"; // basis in default compression mode doesn't look goood for normal maps so just using jpeg for ease
import debugFlagTexture from "../../maps/HELLO.jpg";
import ledTextureSrc from "../../maps/Nike.basis";
import ledTextureGrillSrc from "../../maps/Grill.basis";
import FlagMaterial from "../FlagShaderMaterial";
import TunnelLight from "./TunnelLight";
import Banner from "./Banner";
import Birds from "./Birds";

const IS_DEBUG = window.location.search.indexOf("debug") > -1;
const IS_TRACKBALL = window.location.search.indexOf("trackball") > -1;
const IS_WIREFRAME = window.location.search.indexOf("wireframe") > -1;
const USE_JPEG_TEX = window.location.search.indexOf("jpeg") > -1;

const tmpVec3 = new Vector3();
const MAIN_VIDEO_BETWEEN = [1820, 2600];
// const ROOM_VIDEO_BETWEEN = [1330, 1480];
const CCTV_VIDEO_BETWEEN = [0, 1590];
const BUY_VIDEO_BETWEEM = [2300, 2950];
const CONFETTI_AT = 200;
const CHEER_AT = 180;
const BIRDS_BETWEEN = [200, 600];
const CAMERA_OFFSET_SPEED = 0.002;
const CAMERA_OFFSET_SCALE = 0.45;
const LO_RESOLUTION_MAX = 320 * 568;
const MED_RESOLUTION_MAX = 1200 * 690;

const bulbLuminousPowers = {
  "110000 lm": 110000,
  "60000 lm": 60000,
  "30000 lm": 30000,
  "3500 lm": 3500,
  "1700 lm": 1700,
  "800 lm": 800,
  "400 lm": 400,
  "180 lm": 180,
  "20 lm": 20,
  Off: 0,
};

const textureLoader = new TextureLoader();
const basisLoader = new BasisTextureLoader();
basisLoader.setTranscoderPath("/js/libs/basis/");
// DefaultLoadingManager.onProgress = function(url, itemsLoaded, itemsTotal) {
//   console.log(`Loading ${((itemsLoaded / itemsTotal) * 100).toFixed(2)}%`);
// };

export default class MainScene extends Scene {
  private time = 0;
  private cameraOffset = new Vector3();
  public audioController = new AudioController(this);
  public mainVideo: MainVideo;
  public roomVideo: MainVideo;
  public cctvVideo: MainVideo;
  public buyVideo: MainVideo;
  private banner?: Banner;
  private confetti?: Confetti;
  private tunnelLights: TunnelLight[] = [];

  public primaryView: "fixed" | "interactive" = "interactive";
  private fixedCamera = new PerspectiveCamera(
    50,
    window.innerWidth / window.innerHeight,
    0.1,
    20000,
  );
  public camera = new PerspectiveCamera(
    50,
    window.innerWidth / window.innerHeight,
    0.1,
    20000,
  );
  public pathCamera?: PerspectiveCamera;
  public cameraControls: CameraController | TrackballControls = IS_TRACKBALL
    ? new TrackballControls(this.camera, document.querySelector("canvas"))
    : null;
  public ghosts?: Ghosts;
  public birds?: Birds;
  private pond?: Pond;
  private flags: Object3D[] = [];
  private isCheerTriggered = false;

  private static async getModelMaps() {
    if (window.location.search.indexOf("tex-LO") > -1) {
      return mapsLO;
    }

    if (window.location.search.indexOf("tex-MED") > -1) {
      return mapsMED;
    }

    if (window.location.search.indexOf("tex-HI") > -1) {
      return mapsHI;
    }

    const gl = app.webGLContext;
    const maxTextureSize = parseInt(gl.getParameter(gl.MAX_TEXTURE_SIZE));
    const resolution = window.innerWidth * window.innerHeight;

    console.info(
      `gl.MAX_TEXTURE_SIZE: ${gl.getParameter(gl.MAX_TEXTURE_SIZE)}`,
    );
    console.info(
      `gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS: ${gl.getParameter(
        gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS,
      )}`,
    );

    const networkEffectiveType = await MREurosApp.getNetworkEffectiveType();

    if (resolution <= LO_RESOLUTION_MAX) {
      console.info("Use LO resolution");
      return mapsLO;
    }

    if (
      (networkEffectiveType === "slow-2g" ||
        networkEffectiveType === "2g" ||
        networkEffectiveType === "3g") &&
      resolution <= MED_RESOLUTION_MAX
    ) {
      console.info("Use LO resolution (2g / 3g connection)");
      return mapsLO;
    }

    if (maxTextureSize < 4000) {
      console.info("Use LO resolution (max texture size)");
      return mapsLO;
    }

    if (resolution <= MED_RESOLUTION_MAX) {
      console.info("Use MED resolution");
      return mapsMED;
    }

    if (maxTextureSize < 8000) {
      console.info("Use MED resolution (max texture size)");
      return mapsMED;
    }

    if (MREurosApp.isSafari) {
      console.info("Use MED resolution (is Safari)");
      return mapsMED;
    }

    if (MREurosApp.deviceGrade !== "high") {
      console.info("Use MED resolution (is lower grade device)");
      return mapsMED;
    }

    if (
      networkEffectiveType === "slow-2g" ||
      networkEffectiveType === "2g" ||
      networkEffectiveType === "3g"
    ) {
      console.info("Use MED resolution (2g / 3g connection)");
      return mapsMED;
    }
    console.info("Use HI resolution");
    return mapsHI;
  }

  public get mainCamera(): PerspectiveCamera {
    if (this.primaryView === "fixed") return this.fixedCamera;
    return this.camera;
  }

  public get pipCamera(): PerspectiveCamera {
    if (this.primaryView === "interactive") return this.fixedCamera;
    return this.camera;
  }

  constructor() {
    super();

    if (IS_WIREFRAME) {
      this.overrideMaterial = new MeshBasicMaterial({
        wireframe: true,
        color: 0x0000ff,
      });
    }
  }

  public async init(): Promise<void> {
    if (IS_DEBUG) this.add(new AxesHelper());
    basisLoader.detectSupport(app.webGlView.renderer);

    const ambLight = new AmbientLight(0xffffff, 0.75);
    this.add(ambLight);

    const model = await new GLTFLoader().loadAsync(modelSrc);

    this.add(model.scene);
    this.add(new OuterScene());

    if (!IS_WIREFRAME) {
      const modelMaps = await MainScene.getModelMaps();
      await Promise.all(
        Object.entries(modelMaps).map(
          async ([name, { basis, jpg }]: [
            string,
            { basis: string; jpg: string },
          ]) => {
            const loader = USE_JPEG_TEX ? textureLoader : basisLoader;
            const texture = await loader.loadAsync(
              USE_JPEG_TEX ? (jpg as string) : (basis as string),
            );
            texture.anisotropy = app.webGlView.renderer.capabilities.getMaxAnisotropy();
            texture.flipY = false;
            const objects = this.getObjectsByName(name);

            if (objects.length) {
              objects.forEach(object => {
                if (object instanceof Mesh) {
                  object.material.dispose();
                  const NO_FLAGS = false;
                  if (
                    !NO_FLAGS &&
                    object.name.indexOf("Flag_") > -1 &&
                    MREurosApp.deviceGrade !== "low" &&
                    MREurosApp.deviceGrade !== "medium"
                  ) {
                    const bbox = new Box3();
                    bbox.setFromObject(object);
                    const height = bbox.max.y - bbox.min.y;
                    object.material = new FlagMaterial({
                      size: height,
                      resolution: 12,
                      map: texture,
                    });
                    this.flags.push(object);
                  } else {
                    const Material =
                      MREurosApp.deviceGrade === "low" ||
                      MREurosApp.deviceGrade === "medium" ||
                      MREurosApp.isIPad
                        ? MeshBasicMaterial
                        : MeshLambertMaterial;
                    object.material = new Material({
                      color: 0xffffff,
                      map: texture,
                      side: DoubleSide,
                      transparent:
                        object.name.indexOf("Cutout") > -1 ? true : false,
                    });
                  }
                  console.info(`Attached texture for ${name}`);
                } else {
                  console.warn(`${name} is not a Mesh (${object.type})`);
                }
              });
            } else {
              console.warn(`No objects found for ${name}`);
              texture.dispose();
            }

            return [name, texture];
          },
        ),
      );
    }

    const banner = this.getObjectByName("MR_LL_04_MainBanner");
    if (banner && MREurosApp.deviceGrade !== "low") {
      const map = await basisLoader.loadAsync(ledTextureSrc);
      const grillMap = await basisLoader.loadAsync(ledTextureGrillSrc);
      this.banner = new Banner(banner as Mesh, map, grillMap);
    }

    this.add(this.fixedCamera);
    this.add(this.camera);
    this.pathCamera = this.getObjectByName(
      "BakeCameraPath",
    ) as PerspectiveCamera;

    // // we need the lights for the flags phong
    const dirLightFlags = new DirectionalLight(0xffffff, 0.33);
    dirLightFlags.position.set(1, 10, 1);
    this.add(dirLightFlags);

    const tmp = new Vector3();
    const lightGroup = this.getObjectByName("LightGroup");

    if (
      MREurosApp.deviceGrade !== "low" &&
      MREurosApp.deviceGrade !== "medium" &&
      !MREurosApp.isIPad &&
      !MREurosApp.isSafari
    ) {
      lightGroup.children.forEach(child => {
        (child as Mesh).material = new MeshBasicMaterial({ color: 0xff0000 });
        const tunnelLight = new TunnelLight({
          color: 0xffffff,
          intensity: 0.5,
          distance: 5,
          decay: 1,
          flicker: 1,
        });
        child.getWorldPosition(tunnelLight.position);
        tunnelLight.position.y -= 0.5;
        this.tunnelLights.push(tunnelLight);
        this.add(tunnelLight);
      });
    }
    lightGroup.visible = false;

    this.mainVideo = new MainVideo({
      el: document.querySelector("._js_screen-src"),
      screenMeshes: [
        this.getObjectByName("MR_LL_04_LiveScreen_2") as Mesh,
        this.getObjectByName("MR_LL_04_LiveScreen_1") as Mesh,
        this.getObjectByName("MR_LL_04_LiveScreen_3") as Mesh,
      ],
      hasLight: true,
      hasControls: true,
    });
    this.cctvVideo = new MainVideo({
      el: document.querySelector("._js_cctv-screen-src"),
      screenMeshes: [this.getObjectByName("MR_LL_04_CCTVScreen") as Mesh],
      hasLight: false,
      hasControls: false,
    });
    this.buyVideo = new MainVideo({
      el: document.querySelector("._js_buy-screen-src"),
      screenMeshes: [this.getObjectByName("MR_LL_04_LiveScreenTunnel") as Mesh],
      hasLight: false,
      hasControls: false,
    });

    if (!this.cameraControls) {
      this.cameraControls = new CameraController(this, model.animations);
    }

    const droneObject = this.getObjectByName("DroneGroup");
    if (
      MREurosApp.deviceGrade !== "low" &&
      MREurosApp.deviceGrade !== "medium" &&
      !MREurosApp.isIPad &&
      !MREurosApp.isSafari
    ) {
      if (app.socketsConnectionController) {
        this.ghosts = new Ghosts(droneObject);
        this.add(this.ghosts);
      } else {
        droneObject.parent.remove(droneObject);
      }
      if (!MREurosApp.isMobile) {
        this.birds = new Birds();
        this.add(this.birds);
      }

      this.confetti = new Confetti();
      this.add(this.confetti);
    } else {
      droneObject.parent.remove(droneObject);
    }

    if (MREurosApp.deviceGrade !== "low") {
      const waterObject = this.getObjectByName(
        "MR_LL_04_Waterplaceholder",
      ) as Mesh;
      const mapWaterNormals = await textureLoader.loadAsync(waterNormalsSrc);
      this.pond = new Pond(waterObject, mapWaterNormals);
      this.add(this.pond);
      waterObject.parent.remove(waterObject);
    }

    const loadedEvent = new CustomEvent(AppEvents.Loaded);
    window.dispatchEvent(loadedEvent);
  }

  private getObjectsByName(name: string): Object3D[] {
    const objects = [];
    this.traverse(object => {
      if (object.name === name || object.userData.name === name) {
        objects.push(object);
      }
    });
    return objects;
  }

  public update(delta: number): void {
    this.time += delta;

    this.cameraOffset.set(
      noiseX.noise2D(0, this.time * CAMERA_OFFSET_SPEED) * CAMERA_OFFSET_SCALE,
      noiseY.noise2D(0, this.time * CAMERA_OFFSET_SPEED) *
        CAMERA_OFFSET_SCALE *
        0.5, // make y less so it doesn't go through floors!
      noiseZ.noise2D(0, this.time * CAMERA_OFFSET_SPEED) * CAMERA_OFFSET_SCALE,
    );

    if (this.pathCamera && app.socketsConnectionController) {
      app.socketsConnectionController?.emitPosition(
        this.camera.getWorldPosition(tmpVec3),
      );
    }
    this.flags.forEach(object => {
      object.material.uniforms.time.value += 0.005 * delta;
    });
    this.ghosts?.update(delta);
    this.pond?.update(delta);
    this.banner?.update(delta);
    this.confetti?.update(delta);
    this.audioController.update(this.camera);
    this.cameraControls?.update(delta);
    this.tunnelLights.forEach(light => {
      light.update();
    });

    if (
      this.cameraControls?.needle >= BIRDS_BETWEEN[0] &&
      this.cameraControls?.needle <= BIRDS_BETWEEN[1]
    ) {
      this.birds?.update(delta);
    }

    if (this.mainVideo) {
      if (
        this.cameraControls?.needle >= MAIN_VIDEO_BETWEEN[0] &&
        this.cameraControls?.needle <= MAIN_VIDEO_BETWEEN[1]
      ) {
        if (!this.mainVideo.isActive) {
          window.dispatchEvent(
            new CustomEvent(UIEvents.HideViewfinder, { detail: true }),
          );
          this.mainVideo.isActive = true;
        }
      } else {
        if (this.mainVideo.isActive) {
          window.dispatchEvent(
            new CustomEvent(UIEvents.HideViewfinder, { detail: false }),
          );
          this.mainVideo.isActive = false;
        }
      }
    }
    if (this.cctvVideo) {
      if (
        this.cameraControls?.needle >= CCTV_VIDEO_BETWEEN[0] &&
        this.cameraControls?.needle <= CCTV_VIDEO_BETWEEN[1]
      ) {
        this.cctvVideo.isActive = true;
      } else {
        this.cctvVideo.isActive = false;
      }
    }
    if (this.buyVideo) {
      if (
        this.cameraControls?.needle >= BUY_VIDEO_BETWEEM[0] &&
        this.cameraControls?.needle <= BUY_VIDEO_BETWEEM[1]
      ) {
        this.buyVideo.isActive = true;
      } else {
        this.buyVideo.isActive = false;
      }
    }
    if (this.confetti) {
      if (
        this.cameraControls?.needle >= CONFETTI_AT &&
        !this.confetti.isTriggered
      ) {
        this.confetti.isTriggered = true;
      }
    }

    if (this.cameraControls?.needle >= CHEER_AT && !this.isCheerTriggered) {
      this.isCheerTriggered = true;
      window.dispatchEvent(
        new CustomEvent(UIEvents.PlaySFX, { detail: { key: "emergecrowd1" } }),
      );
    }
  }

  public onResize(): void {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
  }
}
