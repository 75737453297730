import { Color, PointLight } from "three";

class TunnelLight extends PointLight {
  private maxIntensity: number;
  private flicker: number;

  constructor({
    color,
    intensity,
    distance,
    decay,
    flicker,
  }: {
    color?: Color | string | number;
    intensity?: number;
    distance?: number;
    angle?: number;
    penumbra?: number;
    decay?: number;
    flicker?: number;
  }) {
    super(color, intensity, distance, decay);
    this.maxIntensity = intensity || 1;
    this.flicker = flicker || 0.5;
  }

  public update(): void {
    this.intensity =
      this.maxIntensity * 1 -
      this.flicker +
      Math.random() * this.maxIntensity * this.flicker;
  }
}

export default TunnelLight;
