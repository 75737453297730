import { UIEvents } from "../../util/Events";

export default class StartButton {
  private el: HTMLButtonElement;

  constructor(el: HTMLButtonElement) {
    this.el = el;
    this.onClick = this.onClick.bind(this);
    this.el.addEventListener("click", this.onClick);
  }

  public set isVisible(isVisible: boolean) {
    if (isVisible) {
      this.el.style.position = "relative";
      this.el.style.opacity = "1";
      this.el.style.pointerEvents = "all";
      this.el.disabled = false;
    } else {
      this.el.style.position = "absolute";
      this.el.style.opacity = "0";
      this.el.style.pointerEvents = "none";
      this.el.disabled = true;
    }
  }

  private onClick() {
    const userStartEvent = new CustomEvent(UIEvents.Start);
    window.dispatchEvent(userStartEvent);
    window.dispatchEvent(
      new CustomEvent(UIEvents.PlaySFX, {
        detail: { key: "Martine_Rose_OH_1" },
      }),
    );
  }

  public kill(): void {
    requestAnimationFrame(() => {
      this.el.removeEventListener("click", this.onClick);
      this.el.style.display = "none";
    });
  }
}
