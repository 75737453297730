export enum UIEvents {
  Start = "userstart",
  ResumePlayback = "resumeplayback",
  UpdateRoomData = "updateroomdata",
  UpdateSubtitle = "updatesubtitle",
  UpdateCameraPreset = "updatecamerapreset",
  ToggleCredits = "togglecredits",
  CreditsComplete = "creditscomplete",
  MuteUnmute = "muteunmute",
  UserControls = "usercontrols",
  ToggleBuyBanner = "buybanner",
  ToggleSkipTo = "skipto",
  HideViewfinder = "hideviewfinder",
  PlaySFX = "playsfx",
  VideoControls = "videocontrols",
  VideoPlayPause = "videoplaypause",
  GoToFrame = "gotoframe",
}

export enum ScreenEvents {
  AutoplayFailed = "autoplayfailed",
  AutoplaySucceeded = "autoplaysucceeded",
}

export enum AppEvents {
  IntroReady = "introready",
  Ready = "ready",
  Loaded = "loadedapp",
}

export interface IUserControlsEvent extends CustomEvent {
  detail: boolean;
}

export interface IHideViewfinderEvent extends CustomEvent {
  detail: boolean;
}

export interface IMuteUnmuteEvent extends CustomEvent {
  detail: boolean;
}

export interface IToggleCreditsEvent extends CustomEvent {
  detail: boolean;
}

export interface IToggleBuyBannerEvent extends CustomEvent {
  detail: boolean;
}

export interface IToggleSkipToEvent extends CustomEvent {
  detail: boolean;
}

export interface IUpdateSubtitleEvent extends CustomEvent {
  detail: { text: string; duration?: number } | null;
}

export interface IPlaySFXEvent extends CustomEvent {
  detail: { key: string; layer?: string };
}

export interface IToggleVideoControlsEvent extends CustomEvent {
  detail: { isVisible: boolean; isPlaying: boolean };
}

export interface IVideoPlayPauseEvent extends CustomEvent {
  detail: boolean;
}
