import {
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  MultiplyBlending,
  RepeatWrapping,
  Texture,
} from "three";

class Banner {
  private map: Texture;

  constructor(mesh: Mesh, map: Texture, grillMap: Texture) {
    this.map = map;
    this.map.repeat.x = -5;
    this.map.wrapS = RepeatWrapping;
    this.map.wrapT = RepeatWrapping;
    mesh.material = new MeshBasicMaterial({
      map: map,
      side: DoubleSide,
    });

    grillMap.repeat.x = 5;
    grillMap.wrapS = RepeatWrapping;
    grillMap.wrapT = RepeatWrapping;
    const innerMesh = mesh.clone();
    innerMesh.scale.set(0.9999, 1, 0.9999);
    innerMesh.material = new MeshBasicMaterial({
      side: DoubleSide,
      transparent: true,
      map: grillMap,
      opacity: 0.6,
    });
    mesh.parent.add(innerMesh);

    const outerMesh = mesh.clone();
    outerMesh.scale.set(1.0001, 1, 1.0001);
    outerMesh.material = new MeshBasicMaterial({
      side: DoubleSide,
      transparent: true,
      map: grillMap,
      opacity: 0.6,
    });
    mesh.parent.add(outerMesh);
  }

  public update(delta: number): void {
    this.map.offset.x -= 0.0005 * delta;
  }
}

export default Banner;
