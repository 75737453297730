import {
  AudioListener,
  PositionalAudio,
  AudioLoader,
  Object3D,
  PerspectiveCamera,
  Audio,
  MeshBasicMaterial,
  Mesh,
  Vector3,
} from "three";
import { PositionalAudioHelper } from "three/examples/jsm/helpers/PositionalAudioHelper";
import * as dat from "dat.gui";
import MainScene from "./MainScene";
import { gsap } from "gsap/all";
import droneAudioSrc from "../../audio/drone.mp3";
import audioSrcs from "../../audio/selects/44100khz/*.mp3";
import { UIEvents } from "../util/Events";
import { convertToRange } from "../lib/maths.js";
import {
  AUDIO_DATA,
  MAIN_VIDEO_VOLUME,
  MAIN_VIDEO_VOLUME_POSITIONAL,
} from "../data/AudioData";
import TextLabel from "../util/TextLabel";
import MREurosApp from "../../client";

export const DRONE_VOLUME = 0.25;
const IS_DEBUG = window.location.search.indexOf("debug-audio") > -1;

type TLevel = "low" | "high";

let gui;
const settings = {
  ROLLOFF: 4,
  REF_DIST: 5,
  DISTANCE_MODEL: "inverse",
  MAX_DISTANCE: 1000,
  VOLUME: 20,
};
if (IS_DEBUG) {
  gui = new dat.GUI();

  gui.add(settings, "ROLLOFF", 1, 100);
  gui.add(settings, "REF_DIST", 1, 100);
  gui.add(settings, "MAX_DISTANCE", 1, 1000);
  gui.add(settings, "VOLUME", 1, 100);
  gui.add(settings, "DISTANCE_MODEL", ["linear", "inverse", "exponential"]);
  gui.open();
  gui.domElement.parentElement.style.zIndex = "99";
}

export const audioLoader = new AudioLoader();
const ZERO = new Vector3(0, 0, 0);

export default class AudioController {
  private scene: MainScene;
  private filteredAudioListener?: AudioListener;
  private unfilteredAudioListener?: AudioListener;
  private bqfilterAbove?: BiquadFilterNode;
  private droneAudio: Audio;
  private _dronePitch: TLevel = "low";
  private droneControl = { volume: DRONE_VOLUME, playbackRate: 1 };
  private labels: TextLabel[] = [];
  private audios: (PositionalAudio | Audio)[] = [];
  constructor(scene: MainScene) {
    this.scene = scene;

    window.addEventListener(UIEvents.Start, this.setupAudio.bind(this));
    window.addEventListener(UIEvents.MuteUnmute, this.onMuteUnmute.bind(this));
  }

  private async setupAudio() {
    if (this.filteredAudioListener) return;
    this.filteredAudioListener = new AudioListener();
    this.unfilteredAudioListener = new AudioListener();
    this.scene.camera.add(this.filteredAudioListener);

    this.bqfilterAbove = MREurosApp.isMobile
      ? null
      : this.filteredAudioListener.context.createBiquadFilter();
    if (this.bqfilterAbove) {
      this.bqfilterAbove.type = "lowpass";
      this.bqfilterAbove.frequency.value = this.bqfilterAbove.frequency.maxValue;
      this.filteredAudioListener.setFilter(this.bqfilterAbove);
    }

    const mainVideo = document.querySelector(
      "._js_screen-src",
    ) as HTMLVideoElement;
    mainVideo.muted = false;
    mainVideo.volume = MAIN_VIDEO_VOLUME;

    // if (!MREurosApp.isMobile) {
    //   this.addAudio({
    //     parent: this.scene.getObjectByName("MR_LL_04_SoundCube_LiveScreen"),
    //     src: document.querySelector("._js_screen-src") as HTMLVideoElement,
    //     listener: this.unfilteredAudioListener,
    //     volume: settings.VOLUME * MAIN_VIDEO_VOLUME_POSITIONAL,
    //   });
    // }

    const soundMaterial = new MeshBasicMaterial({ color: 0xff0000 });
    const soundGroup = this.scene.getObjectByName("SoundGroup");
    Object.entries(AUDIO_DATA).forEach(([name, data]) => {
      const soundObject = soundGroup.getObjectByName(name);
      if (soundObject) {
        soundObject.children[0].material = soundMaterial;
        const ignore = MREurosApp.isMobile && data && data.ignoreMobile;
        const src = data && !ignore ? (audioSrcs[data.key] as string) : null;
        if (src) {
          const clipVolume = data.volume || 1;
          this.addAudio({
            parent: soundObject,
            isPositional: true,
            isDirectional: data.isDirectional,
            src,
            listener: this.filteredAudioListener,
            volume: MREurosApp.isMobile ? 0.2 : settings.VOLUME * clipVolume,
          });
        }

        if (IS_DEBUG) {
          const label = new TextLabel({
            text: `${soundObject.name} ${
              typeof src === "string" ? `(${src.split("/").pop()})` : "(null)"
            }`,
            camera: this.scene.camera,
          });
          this.labels.push(label);
          soundObject.add(label);
        }
      }
    });

    if (!IS_DEBUG) {
      soundGroup.visible = false;
    }

    this.droneAudio = ((await this.addAudio({
      isPositional: false,
      src: droneAudioSrc,
      volume: DRONE_VOLUME,
      listener: this.unfilteredAudioListener,
    })) as any) as Audio;
  }

  private onMuteUnmute(e) {
    if (e.detail) {
      this.filteredAudioListener.setMasterVolume(0);
      this.unfilteredAudioListener.setMasterVolume(0);
      (document.querySelector(
        "._js_screen-src",
      ) as HTMLVideoElement).muted = true;
    } else {
      this.filteredAudioListener.setMasterVolume(1);
      this.unfilteredAudioListener.setMasterVolume(1);
      (document.querySelector(
        "._js_screen-src",
      ) as HTMLVideoElement).muted = false;
    }
  }

  public async addAudio({
    isPositional = MREurosApp.isMobile ? false : true,
    isDirectional = false,
    parent,
    src,
    listener,
    volume = 1,
  }: {
    isPositional?: boolean;
    isDirectional?: boolean;
    parent?: Object3D;
    src: string | HTMLVideoElement;
    listener: AudioListener;
    volume?: number;
  }): Promise<Audio | PositionalAudio> {
    const AudioType = isPositional ? PositionalAudio : Audio;
    const audio = new AudioType(listener);
    audio.setVolume(0);
    audio.setLoop(true);
    this.audios.push(audio);

    if (isPositional) {
      (audio as PositionalAudio).setRolloffFactor(settings.ROLLOFF);
      (audio as PositionalAudio).setRefDistance(settings.REF_DIST);
    }
    if (isDirectional) {
      (audio as PositionalAudio).setDirectionalCone(120, 180, 0.1);
      audio.lookAt(ZERO);
      audio.updateMatrixWorld();
    }

    if (parent) {
      parent.add(audio);
      parent.lookAt(ZERO);
      // parent.getWorldPosition(audio.position);
    }

    if (IS_DEBUG && isPositional) {
      const helper = new PositionalAudioHelper(audio as PositionalAudio);
      audio.add(helper);
    }

    if (typeof src === "string") {
      const buffer = await audioLoader.loadAsync(src);
      audio.setBuffer(buffer);
      audio.play();
    } else if (src instanceof HTMLVideoElement) {
      src.muted = false;
      audio.setMediaElementSource(src);
    }

    const control = { value: 0 };
    gsap.to(control, {
      value: volume,
      duration: 5,
      ease: "sine.inOut",
      onUpdate: () => audio.setVolume(control.value),
    });

    return audio;
  }

  public get dronePitch(): TLevel {
    return this._dronePitch;
  }

  public set dronePitch(value: TLevel) {
    if (MREurosApp.isMobile) return;
    if (!this.droneAudio) return;
    if (this.dronePitch === value) return;
    this._dronePitch = value;

    gsap.killTweensOf(this.droneControl);
    gsap.to(this.droneControl, {
      playbackRate: value === "low" ? 0.8 : 1,
      volume: value === "low" ? DRONE_VOLUME * 0.7 : DRONE_VOLUME,
      duration: 0.66,
      ease: "sine.inOut",
      onUpdate: () => {
        this.droneAudio.setPlaybackRate(this.droneControl.playbackRate);
        this.droneAudio.setVolume(this.droneControl.volume);
      },
    });
  }

  public update(camera: PerspectiveCamera): void {
    if (IS_DEBUG) {
      this.labels.forEach(label => label.update());
      this.audios.forEach(audio => {
        if (audio instanceof PositionalAudio) {
          (audio.children[0] as PositionalAudioHelper).update();
          audio.setRolloffFactor(settings.ROLLOFF);
          audio.setRefDistance(settings.REF_DIST);
          audio.setDistanceModel(settings.DISTANCE_MODEL);
          audio.setMaxDistance(settings.MAX_DISTANCE);
          audio.setVolume(settings.VOLUME);
        }
      });
    }

    if (this.bqfilterAbove) {
      // change the frequency based on y, going through the tunnel...
      const bqFreqY = convertToRange(
        camera.position.y,
        [-3, 1.5],
        [
          this.bqfilterAbove?.frequency.defaultValue,
          this.bqfilterAbove?.frequency.maxValue,
        ],
      );
      const distFromZeroZero = Math.sqrt(
        camera.position.x * camera.position.x +
          camera.position.z * camera.position.z,
      );

      // and now calculate the final frequence base on if you're in the cinema room or not (don't want BQ filter in the tunnel)
      const bqFreq = convertToRange(
        distFromZeroZero,
        [25, 60],
        [bqFreqY, this.bqfilterAbove?.frequency.maxValue],
      );

      if (this.bqfilterAbove && this.bqfilterAbove.frequency.value !== bqFreq) {
        this.bqfilterAbove.frequency.value = bqFreq;
      }
    }
  }
}
