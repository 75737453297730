import HUDView from "./views/HUDView";
import CreditsView from "./views/CreditsView";
import IntroView from "./views/IntroView";

import { UIEvents, ScreenEvents } from "../util/Events";
import UISfx from "./UISfx";

export default class UIController {
  private hudView?: HUDView;
  private creditsView?: CreditsView;
  private introView?: IntroView;
  private manualPlay: HTMLButtonElement;

  constructor() {
    this.onAutoplayFailed = this.onAutoplayFailed.bind(this);
    this.onAutoplaySucceeded = this.onAutoplaySucceeded.bind(this);
    this.onUserStart = this.onUserStart.bind(this);
    this.handleClickManualPlayButton = this.handleClickManualPlayButton.bind(
      this,
    );
    window.addEventListener(ScreenEvents.AutoplayFailed, this.onAutoplayFailed);
    window.addEventListener(
      ScreenEvents.AutoplaySucceeded,
      this.onAutoplaySucceeded,
    );

    window.addEventListener(UIEvents.Start, this.onUserStart);

    this.manualPlay = document.querySelector("._js_manual-play");
    this.manualPlay.addEventListener("click", this.handleClickManualPlayButton);

    this.introView = new IntroView();
    this.creditsView = new CreditsView();
    new UISfx();
  }

  private onUserStart() {
    this.introView.kill();
    this.hudView = new HUDView();
  }

  private onAutoplayFailed(): void {
    this.manualPlay.style.display = "block";
    console.warn("Autoplay failed, show manual play button");
  }

  private onAutoplaySucceeded(): void {
    // this.manualPlay.style.display = "none";
  }

  private handleClickManualPlayButton(): void {
    this.manualPlay.style.display = "none";
    const resumePlaybackEvent = new CustomEvent(UIEvents.ResumePlayback);
    window.dispatchEvent(resumePlaybackEvent);
  }
}
