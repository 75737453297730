import MREurosApp from "../../client";
import {
  Object3D,
  MathUtils,
  SphereGeometry,
  Vector3,
  DoubleSide,
  Mesh,
  ShaderMaterial,
} from "three";
import {
  PrefabBufferGeometry,
  BasicAnimationMaterial,
  ShaderChunk,
} from "three-bas";

type TProps = {
  particleCount?: number;
};

const MIN_DURATION = 8;
const MAX_DURATION = 8;
const MAX_DELAY = 4;
const MAX_TOTAL_TIME = MAX_DURATION + MAX_DELAY;
class Confetti extends Object3D {
  private mesh: Mesh;
  private time = 0;
  private _isTriggered = false;
  private _isActive = false;

  constructor({
    particleCount = MREurosApp.isMobile ? 1500 : 2500,
  }: TProps = {}) {
    super();
    const prefabGeometry = new SphereGeometry(0.08, 2, 2, 0, 1, 1, 0.5);
    const bufferGeometry = new PrefabBufferGeometry(
      prefabGeometry,
      particleCount,
    );
    bufferGeometry.computeVertexNormals();

    // generate additional geometry data
    // used to calculate animation progress
    const aDelayDuration = bufferGeometry.createAttribute("aDelayDuration", 2);
    // used to calculate position on bezier curve
    // all start positions are (0,0,0), no need to fill that buffer, maybe remove it?
    const aStartPosition = bufferGeometry.createAttribute("aStartPosition", 3);
    const aControlPoint1 = bufferGeometry.createAttribute("aControlPoint1", 3);
    const aControlPoint2 = bufferGeometry.createAttribute("aControlPoint2", 3);
    const aEndPosition = bufferGeometry.createAttribute("aEndPosition", 3);
    // rotation
    const aAxisAngle = bufferGeometry.createAttribute("aAxisAngle", 4);
    // the 'color' attribute is used by three.js
    const aColor = bufferGeometry.createAttribute("color", 3);

    const verticesCount = prefabGeometry.attributes.position.count;

    let i, j, offset;

    // buffer delay duration
    let delay;
    let duration;

    for (i = 0, offset = 0; i < particleCount; i++) {
      delay = MathUtils.randFloat(0, MAX_DELAY);
      duration = MathUtils.randFloat(MIN_DURATION, MAX_DURATION);

      for (j = 0; j < verticesCount; j++) {
        aDelayDuration.array[offset++] = delay;
        aDelayDuration.array[offset++] = duration;
      }
    }

    // buffer control points
    let x, y, z;

    for (i = 0, offset = 0; i < particleCount; i++) {
      x = MathUtils.randFloat(-1, 1);
      y = MathUtils.randFloat(6, 20);
      z = MathUtils.randFloat(-1, 1);

      for (j = 0; j < verticesCount; j++) {
        aControlPoint1.array[offset++] = x;
        aControlPoint1.array[offset++] = y;
        aControlPoint1.array[offset++] = z;
      }
    }

    for (i = 0, offset = 0; i < particleCount; i++) {
      x = MathUtils.randFloat(-8, 8);
      y = MathUtils.randFloat(2, 20);
      z = MathUtils.randFloat(-8, 8);

      for (j = 0; j < verticesCount; j++) {
        aControlPoint2.array[offset++] = x;
        aControlPoint2.array[offset++] = y;
        aControlPoint2.array[offset++] = z;
      }
    }

    for (i = 0, offset = 0; i < particleCount; i++) {
      x = MathUtils.randFloatSpread(0.25);
      y = -1;
      z = MathUtils.randFloatSpread(0.25);

      for (j = 0; j < verticesCount; j++) {
        aStartPosition.array[offset++] = x;
        aStartPosition.array[offset++] = y;
        aStartPosition.array[offset++] = z;
      }
    }

    // buffer end positions

    for (i = 0, offset = 0; i < particleCount; i++) {
      x = MathUtils.randFloatSpread(20);
      y = -1;
      z = MathUtils.randFloatSpread(20);

      for (j = 0; j < verticesCount; j++) {
        aEndPosition.array[offset++] = x;
        aEndPosition.array[offset++] = y;
        aEndPosition.array[offset++] = z;
      }
    }

    // buffer axis angle
    const axis = new Vector3();
    let angle = 0;

    for (i = 0, offset = 0; i < particleCount; i++) {
      axis.x = MathUtils.randFloatSpread(2);
      axis.y = MathUtils.randFloatSpread(2);
      axis.z = MathUtils.randFloatSpread(2);
      axis.normalize();

      angle = Math.PI * MathUtils.randInt(8, 16) + Math.PI * 0.5;

      for (j = 0; j < verticesCount; j++) {
        aAxisAngle.array[offset++] = axis.x;
        aAxisAngle.array[offset++] = axis.y;
        aAxisAngle.array[offset++] = axis.z;
        aAxisAngle.array[offset++] = angle;
      }
    }

    // buffer color
    for (i = 0, offset = 0; i < particleCount; i++) {
      let colour;
      switch (i % 3) {
        case 0:
          colour = { r: 0.85, g: 0.21, b: 0.17 };
          break;
        case 1:
          colour = { r: 0.03, g: 0.15, b: 0.65 };
          break;
        case 2:
          colour = { r: 0.95, g: 0.95, b: 0.95 };
          break;
      }

      for (j = 0; j < verticesCount; j++) {
        aColor.array[offset++] = colour.r;
        aColor.array[offset++] = colour.g;
        aColor.array[offset++] = colour.b;
      }
    }

    const material = new BasicAnimationMaterial({
      side: DoubleSide,
      vertexColors: true,
      uniforms: {
        uTime: { type: "f", value: 0 },
      },
      vertexFunctions: [
        ShaderChunk["quaternion_rotation"],
        ShaderChunk["cubic_bezier"],
        ShaderChunk["ease_cubic_out"],
      ],
      vertexParameters: [
        "uniform float uTime;",
        "attribute vec2 aDelayDuration;",
        "attribute vec3 aStartPosition;",
        "attribute vec3 aControlPoint1;",
        "attribute vec3 aControlPoint2;",
        "attribute vec3 aEndPosition;",
        "attribute vec4 aAxisAngle;",
      ],
      vertexInit: [
        "float tDelay = aDelayDuration.x;",
        "float tDuration = aDelayDuration.y;",
        "float tTime = clamp(uTime - tDelay, 0.0, tDuration);",
        "float tProgress = easeCubicOut(tTime, 0.0, 1.0, tDuration);",

        "float angle = aAxisAngle.w * tProgress;",
        "vec4 tQuat = quatFromAxisAngle(aAxisAngle.xyz, angle);",
      ],
      vertexNormal: ["objectNormal = rotateVector(tQuat, objectNormal);"],
      vertexPosition: [
        "transformed = rotateVector(tQuat, transformed);",
        "transformed += cubicBezier(aStartPosition, aControlPoint1, aControlPoint2, aEndPosition, tProgress);",
      ],
    });

    this.mesh = new Mesh(bufferGeometry, material);
    this.mesh.frustumCulled = false;
    this.add(this.mesh);
  }

  public set isTriggered(value: boolean) {
    this._isTriggered = value;
    this.isActive = value;
  }

  public get isTriggered(): boolean {
    return this._isTriggered;
  }

  public set isActive(value: boolean) {
    this._isActive = value;
    this.visible = value;
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public update(delta: number): void {
    if (!this.isActive) return;

    this.time += (1 / 60) * delta;
    if (this.time >= MAX_TOTAL_TIME) {
      this.time = MAX_TOTAL_TIME;
      this.isActive = false;
    }
    (this.mesh.material as ShaderMaterial).uniforms["uTime"].value = this.time;
  }
}

export default Confetti;
