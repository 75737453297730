import { UIEvents } from "../../util/Events";

export default class MuteButton {
  private el: HTMLButtonElement;
  private _isMuted = false;

  constructor(el: HTMLButtonElement) {
    this.el = el;

    this.onClick = this.onClick.bind(this);

    this.el.addEventListener("click", this.onClick);
  }

  private set isMuted(value: boolean) {
    this._isMuted = value;
    this.el.classList.toggle("is-filled");
    const muteUnmutEvent = new CustomEvent(UIEvents.MuteUnmute, {
      detail: value,
    });
    window.dispatchEvent(muteUnmutEvent);
  }

  private get isMuted(): boolean {
    return this._isMuted;
  }

  private onClick() {
    this.isMuted = !this.isMuted;
  }

  public kill(): void {
    this.el.removeEventListener("click", this.onClick);
  }
}
