import copy from "copy-to-clipboard";

export default class ShareButton {
  private el: HTMLButtonElement;
  private copiedEl: HTMLElement;
  private copyTimeout: any;

  constructor(el: HTMLButtonElement) {
    this.el = el;
    this.copiedEl = document.querySelector("._js_copied");

    this.onClick = this.onClick.bind(this);
    this.onCopy = this.onCopy.bind(this);

    this.el.addEventListener("click", this.onClick);
  }

  private onCopy() {
    this.copiedEl.classList.remove("is-hidden");
    clearTimeout(this.copyTimeout);
    this.copyTimeout = setTimeout(() => {
      this.copiedEl.classList.add("is-hidden");
    }, 2222);
  }

  private copyToClipboard(text: string) {
    try {
      copy(text, {
        onCopy: this.onCopy,
        debug: true,
        format: "text/plain",
      });
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
  }

  private async onClick() {
    const shareData = {
      title: "NIKE x Martine Rose",
      text: "The Lost Lionesses",
      url: window.location.origin,
    };

    if (!navigator.share) {
      return this.copyToClipboard(shareData.url);
    }
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.warn("Could not share", err);
    }
  }

  public kill(): void {
    this.el.removeEventListener("click", this.onClick);
  }
}
