import { UIEvents, IToggleVideoControlsEvent } from "../../util/Events";

type TState = "playing" | "paused";

class PlayPauseButton {
  private el: HTMLButtonElement;
  private playIconEl: HTMLElement;
  private pauseIconEl: HTMLElement;
  private playTextEl: HTMLElement;
  private pauseTextEl: HTMLElement;
  private _state: TState = "paused";

  constructor(el: HTMLButtonElement) {
    this.el = el;
    this.playIconEl = this.el.querySelector("._js_play-pause__icon--play");
    this.pauseIconEl = this.el.querySelector("._js_play-pause__icon--pause");
    this.playTextEl = this.el.querySelector("._js_pop-in__label--play");
    this.pauseTextEl = this.el.querySelector("._js_pop-in__label--pause");
    this.onVideoControlsToggle = this.onVideoControlsToggle.bind(this);
    this.onClick = this.onClick.bind(this);

    this.el.addEventListener("click", this.onClick);
    window.addEventListener(UIEvents.VideoControls, this.onVideoControlsToggle);
  }

  private set state(state: TState) {
    this._state = state;
    if (state === "paused") {
      this.pauseIconEl.classList.add("play-pause__icon--is-hidden");
      this.playIconEl.classList.remove("play-pause__icon--is-hidden");
      this.pauseTextEl.classList.add("pop-in__label--is-hidden");
      this.playTextEl.classList.remove("pop-in__label--is-hidden");
    } else {
      this.pauseIconEl.classList.remove("play-pause__icon--is-hidden");
      this.playIconEl.classList.add("play-pause__icon--is-hidden");
      this.pauseTextEl.classList.remove("pop-in__label--is-hidden");
      this.playTextEl.classList.add("pop-in__label--is-hidden");
    }
  }

  private get state() {
    return this._state;
  }

  private onVideoControlsToggle({ detail }: IToggleVideoControlsEvent) {
    const { isPlaying } = detail;
    if (isPlaying !== undefined) {
      this.state = isPlaying ? "playing" : "paused";
    }
  }

  private onClick() {
    if (this.state === "paused") {
      window.dispatchEvent(
        new CustomEvent(UIEvents.VideoPlayPause, {
          detail: true,
        }),
      );
    } else {
      window.dispatchEvent(
        new CustomEvent(UIEvents.VideoPlayPause, {
          detail: false,
        }),
      );
    }
  }
}

export default PlayPauseButton;
