import MREurosApp from "../client";
import { debounce as _debounce } from "lodash";
// import Draggable from "draggable";
import { Scene, WebGLRenderer } from "three";
import PipController from "./PipController";

import ticker from "./util/Ticker";

export default class WebGLView {
  public renderer: WebGLRenderer;
  public mainScene;
  private width = window.innerWidth;
  private height = window.innerHeight;
  private pipController: PipController;

  get context(): WebGLRenderingContext {
    return this.renderer.getContext();
  }

  constructor(canvas: HTMLCanvasElement, mainScene: Scene) {
    let context;

    if (typeof WebGL2RenderingContext !== "undefined") {
      context = canvas.getContext("webgl2", { antialias: false });
    } else {
      context = canvas.getContext("webgl", { antialias: false });
    }

    this.renderer = new WebGLRenderer({
      canvas,
      context,
      antialias: false,
    });
    // this.renderer.physicallyCorrectLights = true;

    canvas.addEventListener(
      "webglcontextlost",
      () => {
        alert("webglcontextlost");
        const error = this.context.getError();
        if (
          error != this.context.NO_ERROR &&
          error != this.context.CONTEXT_LOST_WEBGL
        ) {
          alert("fail");
        }
      },
      false,
    );

    this.animate = this.animate.bind(this);
    this.update = this.update.bind(this);
    this.render = this.render.bind(this);
    this.onResize = _debounce(this.onResize.bind(this), 333);

    this.onResize();
    this.mainScene = mainScene;
    // this.pipController = new PipController(primaryView => {
    //   this.mainScene.primaryView = primaryView;
    //   this.mainScene.cameraControls.isDisabled = primaryView === "fixed";
    // });
  }

  private resizeRenderer() {
    const maxPixelAspectRadio =
      MREurosApp.deviceGrade === "low"
        ? 1
        : MREurosApp.deviceGrade === "medium"
        ? 1.5
        : 2;

    this.renderer.setPixelRatio(
      Math.min(window.devicePixelRatio, maxPixelAspectRadio),
    );
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  private onResize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.resizeRenderer();
    this.mainScene.onResize();
  }

  public init(): void {
    this.mainScene.init();
    window.addEventListener("resize", this.onResize);
    ticker.addCallback(this.animate);
  }

  private animate(delta: number) {
    this.update(delta);
    this.render();
  }

  private update(delta = 1) {
    this.mainScene.update(delta, this.renderer);
  }

  private render() {
    this.renderer.render(this.mainScene, this.mainScene.mainCamera);

    const RENDER_PIP = false;
    if (RENDER_PIP) {
      this.renderer.setViewport(
        this.pipController.pipRect.left,
        this.height - this.pipController.pipRect.bottom,
        this.pipController.pipRect.width,
        this.pipController.pipRect.height,
      );
      this.renderer.setScissor(
        this.pipController.pipRect.left,
        this.height - this.pipController.pipRect.bottom,
        this.pipController.pipRect.width,
        this.pipController.pipRect.height,
      );
      this.renderer.setScissorTest(true);

      this.renderer.render(this.mainScene, this.mainScene.pipCamera);

      // Reset clip
      this.renderer.setViewport(0, 0, this.width, this.height);
      this.renderer.setScissor(0, 0, this.width, this.height);
      this.renderer.setScissorTest(true);
    }
  }
}
