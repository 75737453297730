import { VideoTexture, MeshBasicMaterial, PointLight, Mesh } from "three";
import { gsap } from "gsap/all";
import iOSVersion from "ios-version";
import FastAverageColor from "fast-average-color";
import MREurosApp from "../../client";
import { IVideoPlayPauseEvent, UIEvents } from "../util/Events";

const fac = new FastAverageColor();

class MainVideo {
  private el;
  private _isActive = false;
  private pointLight?: PointLight;
  private totalPx: number;
  private sampleStep: number;
  private lightRaf?: any;
  private skipSetLightColour = true;
  private hasControls: boolean;
  private volume: number;

  constructor({
    el,
    screenMeshes,
    hasLight,
    hasControls,
    volume = 1,
  }: {
    el: HTMLVideoElement;
    screenMeshes: Mesh[];
    hasLight: boolean;
    hasControls: boolean;
    volume?: number;
  }) {
    this.setLightColour = this.setLightColour.bind(this);
    this.onPlayPause = this.onPlayPause.bind(this);
    this.onPlaying = this.onPlaying.bind(this);
    this.onPaused = this.onPaused.bind(this);
    this.el = el;
    this.hasControls = hasControls;
    this.volume = volume;
    if (hasLight) {
      this.pointLight = new PointLight(0xffffff, 0);
      const center = Math.floor(screenMeshes.length / 2);
      screenMeshes[center].add(this.pointLight);
    }
    const videoMap = new VideoTexture(this.el);
    videoMap.flipY = false;
    screenMeshes.forEach(screen => {
      screen.material = new MeshBasicMaterial({
        map: videoMap,
      });
    });
    this.el.volume = 0;

    this.totalPx = this.el.videoWidth * this.el.videoHeight;
    this.sampleStep = Math.round(this.totalPx / 100);

    if (MREurosApp.videoFrameCallbackSupport && hasLight) {
      this.el.requestVideoFrameCallback(this.setLightColour);
    }
    if (this.hasControls) {
      window.addEventListener(UIEvents.VideoPlayPause, this.onPlayPause);
    }
    this.el.addEventListener("playing", this.onPlaying);
    this.el.addEventListener("paused", this.onPaused);
    this.el.addEventListener("ended", this.onPaused);
  }

  private setLightColour() {
    // only set light colour on every other frame as it's a bit slow
    this.skipSetLightColour = !this.setLightColour;
    if (this.skipSetLightColour) return;

    const colour = fac.getColor(this.el, {
      step: this.sampleStep,
      defaultColor: [255, 255, 255, 0],
    });
    this.pointLight.color.set(colour.hex);
    if (MREurosApp.videoFrameCallbackSupport) {
      this.el.requestVideoFrameCallback(this.setLightColour);
    } else {
      this.lightRaf = requestAnimationFrame(this.setLightColour);
    }
  }

  private onPlaying() {
    if (this.hasControls) {
      window.dispatchEvent(
        new CustomEvent(UIEvents.VideoControls, {
          detail: { isPlaying: true },
        }),
      );
    }
  }

  private onPaused() {
    if (this.hasControls) {
      window.dispatchEvent(
        new CustomEvent(UIEvents.VideoControls, {
          detail: { isPlaying: false },
        }),
      );
    }
  }

  private onPlayPause({ detail }: IVideoPlayPauseEvent) {
    if (!this.isActive) return;
    if (detail) {
      this.el.play();
      window.dispatchEvent(
        new CustomEvent(UIEvents.VideoControls, {
          detail: { isVisible: true, isPlaying: true },
        }),
      );
    } else {
      this.el.pause();
      window.dispatchEvent(
        new CustomEvent(UIEvents.VideoControls, {
          detail: { isVisible: true, isPlaying: false },
        }),
      );
    }
  }

  public set isActive(value: boolean) {
    if (value && !this._isActive) {
      if (this.hasControls) {
        window.dispatchEvent(
          new CustomEvent(UIEvents.VideoControls, {
            detail: { isVisible: true, isPlaying: true },
          }),
        );
      }

      // iOS you cannot animate volume, it's readonly
      if (iOSVersion(navigator.userAgent)) {
        this.el.volume = 1;
        this.el.play();
      } else {
        gsap.killTweensOf(this.el);
        gsap.to(this.el, { volume: this.volume, duration: 2.5 });
        this.el.play();
      }

      cancelAnimationFrame(this.lightRaf);
      if (!MREurosApp.videoFrameCallbackSupport) {
        this.lightRaf = requestAnimationFrame(this.setLightColour);
      }

      if (this.pointLight) {
        gsap.killTweensOf(this.pointLight);
        gsap.to(this.pointLight, { intensity: 1, duration: 1 });
      }
    } else if (!value && this._isActive) {
      if (this.hasControls) {
        window.dispatchEvent(
          new CustomEvent(UIEvents.VideoControls, {
            detail: { isVisible: false, isPlaying: false },
          }),
        );
      }

      if (iOSVersion(navigator.userAgent)) {
        this.el.pause();
      } else {
        gsap.killTweensOf(this.el);
        gsap.to(this.el, {
          volume: 0,
          duration: 2.5,
          onComplete: () => {
            this.el.pause();
          },
        });
        cancelAnimationFrame(this.lightRaf);

        if (this.pointLight) {
          gsap.killTweensOf(this.pointLight);
          gsap.to(this.pointLight, { intensity: 0, duration: 1 });
        }
      }
    }
    this._isActive = value;
  }

  public get isActive(): boolean {
    return this._isActive;
  }
}

export default MainVideo;
