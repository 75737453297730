// Property key is the name of the Sound Group null object
// Property value is the name of the audio file (minus the file extension, ie. .mp3)
// All files need to be in the /audio folder and in MP3 format.

export const MAIN_VIDEO_VOLUME = 1; // when main video is using regular audio (ie mobile)
export const MAIN_VIDEO_VOLUME_POSITIONAL = 1; // when main video is using positional audio

export const AUDIO_DATA = {
  MR_LL_04_SoundCube_1: null,
  MR_LL_04_SoundCube_2: { key: "crowddrums2", volume: 1, ignoreMobile: true }, // ✔
  MR_LL_04_SoundCube_3: {
    key: "cafe",
    ignoreMobile: true,
    isDirectional: true,
    volume: 1,
  }, // ✔
  MR_LL_04_SoundCube_4: {
    key: "recordstoreloop",
    ignoreMobile: true,
    isDirectional: true,
    volume: 0.15,
  }, // ✔
  MR_LL_04_SoundCube_5: {
    key: "laundrette",
    ignoreMobile: true,
    isDirectional: true,
    volume: 1,
  }, // ✔
  MR_LL_04_SoundCube_6: null,
  MR_LL_04_SoundCube_7: {
    key: "peopleshop",
    ignoreMobile: true,
    isDirectional: true,
    volume: 1,
  },
  MR_LL_04_SoundCube_8: { key: "0-sound-water" }, // ✔
  MR_LL_04_SoundCube_9: null,
  MR_LL_04_SoundCube_10: {
    key: "1-sound-birds",
    ignoreMobile: true,
    volume: 1,
  }, // ✔
  MR_LL_04_SoundCube_11: null,
  MR_LL_04_SoundCube_12: {
    key: "crowdtube_tubelouder",
    ignoreMobile: true,
    volume: 1,
  }, // ✔
  MR_LL_04_SoundCube_13: null,
  MR_LL_04_SoundCube_14: null,
  MR_LL_04_SoundCube_15: null,
  MR_LL_04_SoundCube_16: null,
  MR_LL_04_SoundCube_17: null,
  MR_LL_04_SoundCube_18: null,
  MR_LL_04_SoundCube_19: null,
  MR_LL_04_SoundCube_20: null,
  MR_LL_04_SoundCube_21: null,
  MR_LL_04_SoundCube_22: null,
  MR_LL_04_SoundCube_23: null,
  MR_LL_04_SoundCube_24: null,
  MR_LL_04_SoundCube_25: null,
  MR_LL_04_SoundCube_26: null,
  MR_LL_04_SoundCube_27: null,
};

// export const AUDIO_DATA = {
//   MR_LL_04_SoundCube_3: "cafe", // ✔
// };
