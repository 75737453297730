import { AnimationMixer, AnimationClip, Vector3 } from "three";
import {
  clamp as _clamp,
  debounce as _debounce,
  throttle as _throttle,
} from "lodash";
import SimplexNoise from "simplex-noise";
import MainScene from "./MainScene";
import { UIEvents } from "../util/Events";
import { convertToRange } from "../lib/maths.js";
import MREurosApp from "../../client";

const TOUCHPOINTS = [
  {
    frame: 30,
    caption:
      "In 1971 a fearless group of young women walked into the Azteca Stadium",
    voice: "3-in-1971-the-lost-lionesses",
  },
  {
    frame: 390,
    caption: "The noise of 90,000 fans around them.",
    voice: "5-the-noise-of-90000-fans-around-them",
  },
  {
    frame: 510,
    caption: "The air so hot they could hardly breathe.",
    voice: "6-the-air-so-hot-they-could-hardly-breath",
  },
  {
    frame: 620,
    caption: "But it’s not only players who make sacrifices.",
    voice: "7-but-its-not-only-players-who-make-sacrafices",
  },
  {
    frame: 720,
    caption: "We’re the ones who gather on wet pitches,",
    voice: "8-we-are-the-ones-who-gather-on-wet-pitches",
  },
  {
    frame: 800,
    caption: "school halls, pubs, or anywhere with a big enough TV.",
    voice: "9-in-school-halls-pubs",
  },
  {
    frame: 920,
    caption: "We catch flights and buses to Rome, Bilbao, or Budapest,",
    voice: "10-we-catch-flights-and-buses",
  },
  {
    frame: 1180,
    caption: "or maybe just the next town over.",
    voice: "11-or-maybe-just-the-next-town-over",
  },
  {
    frame: 1320,
    caption: "Did you miss us while we were gone?",
    voice: "12-did-you-miss-us-while-were-gone",
  },
  {
    frame: 1450,
    caption: "That’s the thing about football – it’s yours.",
    voice: "22-thats-the-thing-about-football",
  },
  {
    frame: 1550,
    caption: "If you turn up every Sunday morning – it’s yours.",
    voice: "23-if-you-turn-up-every-sunday-morning-its-yours",
  },
  {
    frame: 1700,
    caption: "If you cover your house in flags. It’s yours.",
    voice: "24-if-you-cover-your-house-in-flags-its-yours",
  },
  {
    frame: 1820,
    caption: "Whoever you are and whatever you do. It’s yours.",
    voice: "25-whoever-you-are-and-whatever-you-do",
  },
  {
    frame: 2400,
    caption: "Martine Rose has reconstructed the iconic England jersey.",
    voice: "27-martine-rose-has-reconstructed-the-iconic-england-jersey",
  },
  {
    frame: 2600,
    caption: "It’s a double crested, reversible and genderless",
    voice: "28-its-double-brested-reversable-and-genderless",
  },

  {
    frame: 2840,
    caption: "The future of football won’t be decided on the pitch.",
    voice: "29-the-future-of-football-wont-be-decided-on-the-pitch",
  },
  {
    frame: 3000,
    caption: "It’ll be made by the extraordinary people on the terraces",
    voice: "30-itll-be-made-by-the-extraordinary-people",
  },
  {
    frame: 3100,
    caption: "at home, or in the park,",
    voice: "31-at-home-or-in-the-park",
  },
  {
    frame: 3200,
    caption: "where it’s open to anyone who wants to play.",
    voice: "32-where-its-open-to-anyone-who-wants-to-play",
  },
  {
    frame: 3300,
    caption: "This is the future of football.",
    voice: "33-this-is-the-future-of-football",
  },
  {
    frame: 3400,
    caption: "Whoever you are: it’s yours.",
    voice: "34-whoever-you-are-its-yours",
  },
];

const BUY_BANNER_VISIBLE_FRAMES = [2770, 2950];
const SKIP_TO_VIDEO_VISIBLE_FRAMES = [230, 1800];

const CAMERA_OFFSET_SPEED = 0.065;
const CAMERA_OFFSET_SCALE = 0.5;
const WHEEL_MULTI =
  window.navigator.platform.toLowerCase().indexOf("mac") > -1 ? 1 : 1.5; // multiplier for mousewheel scrolling
const TOUCH_MULTI = 3; // multiplier for touchmove scrolling
const SCROLL_MULTI = 0.0009;
const MAX_SCROLL_SPEED = 42;

const noiseX = new SimplexNoise("x");
const noiseY = new SimplexNoise("y");
const noiseZ = new SimplexNoise("z");

const UP = new Vector3(0, 1, 0);
const ACROSS = new Vector3(1, 0, 0);

export default class CameraController {
  private scene: MainScene;
  private mixer?: AnimationMixer;
  private cmdPressed = false;
  private shiftPressed = false;
  private scrollAnimationTime = 0;
  // private currentTouchpointIndex: null | number = null;
  private currentTouchpointId: null | number = null;
  private isBuyBannerVisible = false;
  private isSkipToVisible = false;
  private clipLengthFrames = 0;
  private previousTouchY: number | null = null;
  private currentTouchDelta = 0;
  private time = 0;
  public prevNearestFrameFract = 0;
  public nearestFrameFract = 0;
  public velocity = 0;
  public needle = 0;
  private cameraOffset = new Vector3();
  private rotationOffsetX = 0;
  private rotationOffsetTargetX = 0;
  private rotationOffsetY = 0;
  private rotationOffsetTargetY = 0;
  private wWidth = window.innerWidth;
  private wHeight = window.innerHeight;
  private orientationCalibrationValues: number[] = [];
  private hasDoneFullLoop = false;
  private isEnabled = true;
  private isCreditsVisible = false;

  constructor(scene: MainScene, animations: AnimationClip[]) {
    this.scene = scene;
    this.animateTouchScroll = this.animateTouchScroll.bind(this);
    this.onMoving = this.onMoving.bind(this);
    this.onStopMoving = _debounce(this.onStopMoving.bind(this), 333);
    this.init = this.init.bind(this);
    this.goToFrame = this.goToFrame.bind(this);

    window.addEventListener(UIEvents.Start, this.init);
    window.addEventListener(UIEvents.GoToFrame, this.goToFrame);
    window.addEventListener(
      UIEvents.ToggleCredits,
      ({ detail }: CustomEvent) => {
        this.isCreditsVisible = detail;
      },
    );

    this.mixer = new AnimationMixer(this.scene);
    this.clipLengthFrames = animations[0].duration * 30; // 30FPS
    const action = this.mixer.clipAction(animations[0]);
    action.play();
    this.mixer.update(0);
  }

  private init() {
    this.onOrientation = _throttle(this.onOrientation.bind(this), 17);
    window.addEventListener("mousemove", this.onMouseMove.bind(this), {
      passive: true,
    });
    window.addEventListener("deviceorientation", this.onOrientation, {
      passive: true,
    });
    window.addEventListener("wheel", this.onMouseWheel.bind(this), {
      passive: true,
    });
    window.addEventListener("touchmove", this.onTouchMove.bind(this), {
      passive: true,
    });
    window.addEventListener("touchstart", this.onTouchStart.bind(this), {
      passive: true,
    });
    window.addEventListener("keydown", e => {
      if (e.key.toLowerCase() === "meta") this.cmdPressed = true;
      if (e.key.toLowerCase() === "shift") this.shiftPressed = true;
    });
    window.addEventListener("keyup", e => {
      if (e.key.toLowerCase() === "meta") this.cmdPressed = false;
      if (e.key.toLowerCase() === "shift") this.shiftPressed = false;
    });
    window.addEventListener("focus", () => {
      this.cmdPressed = false;
      this.shiftPressed = false;
    });
    window.addEventListener("blur", () => {
      this.cmdPressed = false;
      this.shiftPressed = false;
    });
    window.addEventListener(
      "resize",
      _debounce(() => {
        this.wWidth = window.innerWidth;
        this.wHeight = window.innerHeight;
      }, 333),
    );
  }

  private checkTouchpoint(nearestFrame: number, disableCaptions = false) {
    this.needle = Math.abs(nearestFrame % this.clipLengthFrames);
    if (nearestFrame < 0) {
      this.needle = this.clipLengthFrames - this.needle;
    }

    if (nearestFrame > this.clipLengthFrames && !this.hasDoneFullLoop) {
      this.hasDoneFullLoop = true;
      window.dispatchEvent(
        new CustomEvent(UIEvents.ToggleCredits, {
          detail: true,
        }),
      );
    }

    if (!disableCaptions && !this.isCreditsVisible) {
      let closest;
      for (let i = TOUCHPOINTS.length - 1; i >= 0; i--) {
        if (
          this.needle >= TOUCHPOINTS[i].frame &&
          Math.abs(this.needle - TOUCHPOINTS[i].frame) < 5
        ) {
          closest = TOUCHPOINTS[i];
          break;
        }
      }

      if (closest && this.currentTouchpointId !== closest.frame) {
        this.currentTouchpointId = closest.frame;
        const { caption, duration, voice } = closest;
        const updateSubtitleEvent = new CustomEvent(UIEvents.UpdateSubtitle, {
          detail: { text: caption, duration },
        });
        window.dispatchEvent(updateSubtitleEvent);
        const playSfxEvent = new CustomEvent(UIEvents.PlaySFX, {
          detail: { key: voice, layer: "voice" },
        });
        window.dispatchEvent(playSfxEvent);
      }
    }

    if (
      this.needle >= BUY_BANNER_VISIBLE_FRAMES[0] &&
      this.needle <= BUY_BANNER_VISIBLE_FRAMES[1]
    ) {
      if (!this.isBuyBannerVisible) {
        this.isBuyBannerVisible = true;
        window.dispatchEvent(
          new CustomEvent(UIEvents.ToggleBuyBanner, {
            detail: true,
          }),
        );
      }
    } else {
      if (this.isBuyBannerVisible) {
        this.isBuyBannerVisible = false;
        window.dispatchEvent(
          new CustomEvent(UIEvents.ToggleBuyBanner, {
            detail: false,
          }),
        );
      }
    }

    if (
      this.needle >= SKIP_TO_VIDEO_VISIBLE_FRAMES[0] &&
      this.needle <= SKIP_TO_VIDEO_VISIBLE_FRAMES[1]
    ) {
      if (!this.isSkipToVisible) {
        this.isSkipToVisible = true;
        window.dispatchEvent(
          new CustomEvent(UIEvents.ToggleSkipTo, {
            detail: true,
          }),
        );
      }
    } else {
      if (this.isSkipToVisible) {
        this.isSkipToVisible = false;
        window.dispatchEvent(
          new CustomEvent(UIEvents.ToggleSkipTo, {
            detail: false,
          }),
        );
      }
    }
  }

  private onMoving() {
    this.scene.audioController.dronePitch = "high";
    this.onStopMoving();
  }

  private onStopMoving() {
    this.scene.audioController.dronePitch = "low";
  }

  private onTouchStart(e) {
    if (!this.isEnabled) return;
    this.previousTouchY = e.touches[0].clientY;
  }

  private onTouchMove(e: any) {
    if (!this.isEnabled) return;
    this.onMoving();
    const deltaY = this.previousTouchY - e.touches[0].clientY;
    this.previousTouchY = e.touches[0].clientY;
    this.currentTouchDelta = _clamp(
      deltaY,
      -MAX_SCROLL_SPEED,
      MAX_SCROLL_SPEED,
    );
    cancelAnimationFrame(this.raf);
    this.raf = requestAnimationFrame(this.animateTouchScroll);
  }

  private animateTouchScroll() {
    if (!this.isEnabled) return;
    this.currentTouchDelta += (0 - this.currentTouchDelta) * 0.05;

    const scale = this.cmdPressed ? 5 : 1;
    this.scrollAnimationTime +=
      this.currentTouchDelta * scale * SCROLL_MULTI * TOUCH_MULTI;
    if (this.mixer) {
      this.mixer.update(
        this.currentTouchDelta * scale * SCROLL_MULTI * TOUCH_MULTI,
      );
    }

    this.nearestFrameFract = this.mixer.time * 30;
    this.checkTouchpoint(
      Math.round(this.nearestFrameFract),
      this.currentTouchDelta < 0,
    );

    if (Math.abs(this.currentTouchDelta - 0) < 0.1) {
      this.currentTouchDelta = 0;
      return;
    }
    this.raf = requestAnimationFrame(this.animateTouchScroll);
  }

  private onOrientation(event: DeviceOrientationEvent) {
    const { alpha, beta, gamma } = event;
    const MAX_INPUT_BETA = 25;
    const MAX_INPUT_GAMMA = 35;

    let _beta = this.wWidth < this.wHeight ? beta : gamma;
    if (this.orientationCalibrationValues.length > 60 * 6) {
      this.orientationCalibrationValues.shift();
    }
    this.orientationCalibrationValues.push(_beta);
    let calibration = this.orientationCalibrationValues.reduce((acc, val) => {
      return acc + val;
    });
    calibration /= this.orientationCalibrationValues.length;
    _beta -= calibration;

    this.rotationOffsetTargetX = convertToRange(
      _beta,
      [-MAX_INPUT_BETA, MAX_INPUT_BETA],
      [-0.12, 0.12],
    );
    this.rotationOffsetTargetY = 0;

    if (alpha < 180) {
      this.rotationOffsetTargetY = convertToRange(
        alpha,
        [0, MAX_INPUT_GAMMA],
        [0, -0.12],
      );
    } else {
      this.rotationOffsetTargetY = convertToRange(
        alpha,
        [360 - MAX_INPUT_GAMMA, 360],
        [0.12, 0],
      );
    }
  }

  private onMouseMove(e: any) {
    if (MREurosApp.isMobile) return;
    this.rotationOffsetTargetX = convertToRange(
      e.clientY,
      [0, this.wHeight],
      [0.2, -0.2],
    );
    this.rotationOffsetTargetY = convertToRange(
      e.clientX,
      [0, this.wWidth],
      [0.3, -0.3],
    );
  }

  private onMouseWheel(e: any): void {
    if (!this.isEnabled) return;
    this.onMoving();
    const scale = this.cmdPressed ? 5 : 1;
    const deltaY = _clamp(e.deltaY, -MAX_SCROLL_SPEED, MAX_SCROLL_SPEED);
    this.scrollAnimationTime += deltaY * scale * SCROLL_MULTI * WHEEL_MULTI;
    if (this.mixer)
      this.mixer.update(deltaY * scale * SCROLL_MULTI * WHEEL_MULTI);
    // if (this.mixer.time < 0) {
    //   this.mixer.setTime(0);
    // }

    this.nearestFrameFract = this.mixer.time * 30;
    this.checkTouchpoint(Math.round(this.nearestFrameFract), deltaY < 0);

    const distFromZeroZero = Math.sqrt(
      this.scene.camera.position.x * this.scene.camera.position.x +
        this.scene.camera.position.z * this.scene.camera.position.z,
    );
    if (this.shiftPressed) {
      console.log(
        `time: ${this.scrollAnimationTime}\n
        needle: ${this.needle} / ${this.clipLengthFrames}\n
        nearest frame: ${Math.round(this.scrollAnimationTime * 30)}\n
        position: ${this.scene.camera.position.x}, ${
          this.scene.camera.position.y
        }, ${this.scene.camera.position.z}\n
        rotation: ${this.scene.camera.rotation.x}, ${
          this.scene.camera.rotation.y
        }, ${this.scene.camera.rotation.z}
        distFromZeroZero: ${distFromZeroZero}`,
      );
    }
  }

  private goToFrame({ detail }: CustomEvent) {
    const time = detail / 30;
    this.currentTouchDelta = 0;
    this.mixer.setTime(time);
    this.checkTouchpoint(detail, true);
  }

  public update(delta: number): void {
    this.velocity =
      Math.abs(this.prevNearestFrameFract - this.nearestFrameFract) * delta;
    this.prevNearestFrameFract = this.nearestFrameFract;
    this.time += (1 / 60) * delta;
    this.cameraOffset.set(
      noiseX.noise2D(0, this.time * CAMERA_OFFSET_SPEED) * CAMERA_OFFSET_SCALE,
      noiseY.noise2D(0, this.time * CAMERA_OFFSET_SPEED) *
        CAMERA_OFFSET_SCALE *
        0.5, // make y less so it doesn't go through floors!
      noiseZ.noise2D(0, this.time * CAMERA_OFFSET_SPEED) * CAMERA_OFFSET_SCALE,
    );
    this.rotationOffsetX +=
      (this.rotationOffsetTargetX - this.rotationOffsetX) * 0.1;
    this.rotationOffsetY +=
      (this.rotationOffsetTargetY - this.rotationOffsetY) * 0.1;

    if (this.scene.camera) {
      this.scene.camera.position.copy(this.scene.pathCamera.position);
      this.scene.camera.rotation.copy(this.scene.pathCamera.rotation);
      this.scene.camera.position.add(this.cameraOffset);

      this.scene.camera.rotateOnAxis(UP, this.rotationOffsetY);
      this.scene.camera.rotateOnAxis(ACROSS, this.rotationOffsetX);
    }
  }
}
