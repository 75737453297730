type TStates = "buy" | "play-pause" | "scroll" | null;

export default class PopIns {
  private el: HTMLElement;
  private buyEl: HTMLElement;
  private playPauseEl: HTMLElement;
  private scrollEl: HTMLElement;
  private skipEl: HTMLElement;
  private states = { buy: false, playPause: false, scroll: false, skip: false };

  constructor(el: HTMLElement) {
    this.el = el;
    this.buyEl = this.el.querySelector("._js_pop-in--buy");
    this.playPauseEl = this.el.querySelector("._js_pop-in--play-pause");
    this.scrollEl = this.el.querySelector("._js_pop-in--scroll");
    this.skipEl = this.el.querySelector("._js_pop-in--skip");
  }

  public set isSkipVisible(value: boolean) {
    this.states.skip = value;
    this.setVisibility(this.skipEl, value);
  }

  public set isBuyVisible(value: boolean) {
    this.states.buy = value;
    this.calculateState();
  }

  public set isPlayPauseVisible(value: boolean) {
    this.states.playPause = value;
    this.calculateState();
  }

  public set isScrollVisible(value: boolean) {
    this.states.scroll = value;
    this.calculateState();
  }

  private set state(state: TStates) {
    switch (state) {
      case null:
        this.setVisibility(this.buyEl, false);
        this.setVisibility(this.playPauseEl, false);
        this.setVisibility(this.scrollEl, false);
        break;
      case "buy":
        this.setVisibility(this.buyEl, true);
        this.setVisibility(this.playPauseEl, false);
        this.setVisibility(this.scrollEl, false);
        break;
      case "play-pause":
        this.setVisibility(this.buyEl, false);
        this.setVisibility(this.playPauseEl, true);
        this.setVisibility(this.scrollEl, false);
        break;
      case "scroll":
        this.setVisibility(this.buyEl, false);
        this.setVisibility(this.playPauseEl, false);
        this.setVisibility(this.scrollEl, true);
        break;
    }
  }

  private calculateState() {
    if (this.states.buy) {
      this.state = "buy";
    } else if (this.states.playPause) {
      this.state = "play-pause";
    } else if (this.states.scroll) {
      this.state = "scroll";
    } else {
      this.state = null;
    }
  }

  private setVisibility(el: HTMLElement, isVisible: boolean) {
    if (isVisible) {
      el.classList.add("pop-in--is-visible");
      el.setAttribute("aria-hidden", "false");
    } else {
      el.classList.remove("pop-in--is-visible");
      el.setAttribute("aria-hidden", "true");
    }
  }

  public kill(): void {
    this.el.style.display = "none";
  }
}
