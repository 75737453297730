import {
  Object3D,
  Texture,
  RepeatWrapping,
  MeshBasicMaterial,
  Vector3,
  FrontSide,
  DoubleSide,
  AmbientLight,
  RingBufferGeometry,
} from "three";
import { Water } from "three/examples/jsm/objects/Water";

const WATER_COLOUR = 0x157c53;

class Pond extends Object3D {
  private water: Object3D;

  constructor(waterObject: Object3D, waterNormalsTexture: Texture) {
    super();

    const underWater = waterObject.clone();
    underWater.material = new MeshBasicMaterial({
      color: WATER_COLOUR,
      transparent: true,
      opacity: 0.75,
    });
    underWater.position.set(0, -0.01, 0);
    this.add(underWater);

    const pondGeometry = waterObject.geometry.clone();
    waterNormalsTexture.wrapS = waterNormalsTexture.wrapT = RepeatWrapping;
    this.water = new Water(pondGeometry, {
      textureWidth: 512,
      textureHeight: 512,
      clipBias: 0.1,
      waterNormals: waterNormalsTexture,
      alpha: 0.8,
      sunDirection: new Vector3(0, 1, 0),
      sunColor: 0xffffff,
      waterColor: WATER_COLOUR,
      distortionScale: 3.7,
      fog: false,
    });

    this.water.material.transparent = true;
    this.water.material.uniforms["size"].value = 5.0;
    this.add(this.water);
  }

  public updateSunPosition(position: Vector3): void {
    this.water.material.uniforms["sunDirection"].value
      .copy(position)
      .normalize();
  }

  public update(delta: number): void {
    this.water.material.uniforms["time"].value += (0.25 / 60.0) * delta;
  }
}

export default Pond;
