import Stats from "stats.js";

class Ticker {
  private raf: any;
  private delta = 1;
  private then: number | null = null;
  private now: number | null = null;
  private callbacks = [];
  private stats?: Stats;

  constructor() {
    this.animate = this.animate.bind(this);

    if (["fps"].find(value => window.location.search.indexOf(value) > -1)) {
      this.stats = new Stats();
      this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
      document.body.appendChild(this.stats.dom);
    }
  }

  private calculateDelta() {
    this.then = this.now ? this.now : null;
    this.now = new Date().getTime();
    this.delta = this.then ? (this.now - this.then) / 16.666 : 1;
    this.delta = Math.min(this.delta, 3);
  }

  private animate() {
    if (this.stats) this.stats.begin();
    this.calculateDelta();
    this.callbacks.forEach(callback => callback(this.delta));
    this.raf = requestAnimationFrame(this.animate);
    if (this.stats) this.stats.end();
  }

  public addCallback(callback: Function): void {
    this.callbacks.push(callback);

    cancelAnimationFrame(this.raf);
    this.then = Date.now();
    this.raf = requestAnimationFrame(this.animate);
  }

  public removeCallback(callback: Function): void {
    this.callbacks.splice(this.callbacks.indexOf(callback), 1);
    if (this.callbacks.length === 0) {
      cancelAnimationFrame(this.raf);
    }
  }

  public hasCallback(callback: Function): boolean {
    return this.callbacks.indexOf(callback) > -1;
  }
}

export default new Ticker();
