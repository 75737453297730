export const SUGGESTIONS = [
  "Find a peaceful corner, somewhere you won’t be disturbed.",
  "Switch off anything that beeps, blinks, shudders or vibrates.",
  "We want your bandwidth and we want your attention to ourselves.",
  "Plug in headphones or speakers if you have them.",
  "Try to stay with us from beginning to end.",
  "Enjoy yourself.",
];

export const SUGGESTIONS_DURATION = 5000;

export default class Suggestions {
  private el: HTMLDivElement;
  private innerEl: HTMLElement;
  private currentIndex: any;
  private timer: any;

  constructor(el: HTMLDivElement) {
    this.el = el;
    this.innerEl = this.el.querySelector("._js_suggestions__subtitle");
    this.currentIndex = 0;
    this.init = this.init.bind(this);
  }

  public init(): void {
    this.el.style.display = "block";
    this.innerEl.innerHTML = SUGGESTIONS[0];
    this.timer = setInterval(() => {
      if (this.currentIndex === SUGGESTIONS.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex = this.currentIndex + 1;
      }
      this.innerEl.innerHTML = SUGGESTIONS[this.currentIndex];
    }, SUGGESTIONS_DURATION);
  }

  public kill(): void {
    requestAnimationFrame(() => {
      clearInterval(this.timer);
      this.el.style.display = "none";
    });
  }
}
