import LoadingBar from "../components/LoadingBar";
import StartButton from "../components/StartButton";
import { AppEvents } from "../../util/Events";
import Suggestions from "../components/Suggestions";

export default class IntroView {
  private el: HTMLElement;
  private start: StartButton;
  private video: HTMLVideoElement;
  private suggestions: Suggestions;
  private loading: LoadingBar;

  constructor() {
    this.el = document.querySelector("._js_intro-view");
    this.onExperienceLoaded = this.onExperienceLoaded.bind(this);

    this.start = new StartButton(document.querySelector("._js_start"));
    this.video = document.querySelector("._js_intro-video");
    this.suggestions = new Suggestions(
      document.querySelector("._js_suggestions"),
    );
    this.loading = new LoadingBar(document.querySelector("._js_loading"));
    this.loading.isVisible = true;

    this.suggestions.init();

    window.addEventListener(AppEvents.Loaded, this.onExperienceLoaded);
    document.body.style.cursor = "wait";
  }

  public onExperienceLoaded(): void {
    document.body.style.cursor = "";
    this.loading.kill();
    this.start.isVisible = true;
  }

  public kill(): void {
    requestAnimationFrame(() => {
      this.el.style.display = "none";
    });
    this.suggestions.kill();
    this.start.kill();
    this.video.pause();
  }
}
