export default class FullscreenButton {
  private el: HTMLButtonElement;
  private _isFullscreen = false;

  constructor(el: HTMLButtonElement) {
    this.el = el;

    this.onClick = this.onClick.bind(this);
    this.onFullscreenChange = this.onFullscreenChange.bind(this);

    this.el.addEventListener("click", this.onClick);
    document.addEventListener(
      "fullscreenchange",
      this.onFullscreenChange,
      false,
    );

    document.addEventListener(
      "mozfullscreenchange",
      this.onFullscreenChange,
      false,
    );

    document.addEventListener(
      "webkitfullscreenchange",
      this.onFullscreenChange,
      false,
    );
  }

  private set isFullscreen(value: boolean) {
    this._isFullscreen = value;
    this.el.classList.toggle("is-filled");
  }

  private get isFullscreen(): boolean {
    return this._isFullscreen;
  }

  private onFullscreenChange() {
    if (
      !document.fullscreenElement && // alternative standard method
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      this.isFullscreen = false;
    } else {
      this.isFullscreen = true;
    }
  }

  private onClick() {
    if (
      !document.fullscreenElement && // alternative standard method
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  public kill(): void {
    this.el.removeEventListener("click", this.onClick);
  }
}
