export default class Subtitles {
  private el: HTMLElement;
  private innerEl: HTMLElement;
  private clearTo?: any;

  constructor(el: HTMLElement) {
    this.el = el;
    this.innerEl = this.el.querySelector("._js_subtitles__subtitle");
  }

  public set subtitle(
    value: {
      text: string;
      duration?: number;
    } | null,
  ) {
    clearTimeout(this.clearTo);
    requestAnimationFrame(() => {
      if (value === null) {
        this.el.style.display = "none";
        this.innerEl.innerHTML = "";
      } else {
        const { text, duration } = value;
        this.innerEl.innerHTML = text;
        this.el.style.display = "block";

        this.clearTo = setTimeout(() => {
          this.subtitle = null;
        }, duration || 3333);
      }
    });
  }

  public kill(): void {
    this.el.style.display = "none";
  }
}
