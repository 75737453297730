import voicesSrcs from "../../audio/voices/*.mp3";
import sfxSrcs from "../../audio/sfx/*.mp3";
import {
  AudioContext as THREEAudioContext,
  AudioListener,
  Audio,
  AudioLoader,
} from "three";
import { UIEvents, IMuteUnmuteEvent, IPlaySFXEvent } from "../util/Events";

const audioLoader = new AudioLoader();

class UISfx {
  private sfx: Map<string, Audio> = new Map();
  private listener: AudioListener = new AudioListener();
  private layerStates: { [key: string]: boolean } = {};

  constructor() {
    this.onUserStart = this.onUserStart.bind(this);
    this.play = this.play.bind(this);
    window.addEventListener(
      UIEvents.MuteUnmute,
      ({ detail }: IMuteUnmuteEvent) => (this.isMuted = detail),
    );
    window.addEventListener(UIEvents.Start, this.onUserStart);
    window.addEventListener(UIEvents.PlaySFX, this.play);
    this.loadAudio();
  }

  public set isMuted(value: boolean) {
    if (value) {
      this.listener.setMasterVolume(0);
    } else {
      this.listener.setMasterVolume(1);
    }
  }

  private async loadAudio() {
    const srcs = [...Object.entries(voicesSrcs), ...Object.entries(sfxSrcs)];
    await Promise.all(
      srcs.map(async ([key, value]) => {
        const audio = new Audio(this.listener);
        audio.setVolume(2);
        audio.setLoop(false);

        const buffer = await audioLoader.loadAsync(value as string);
        audio.setBuffer(buffer);
        this.sfx.set(key, audio);
      }),
    );
  }

  private onUserStart(): void {
    this.requestPermission();
  }

  private requestPermission() {
    const audioContext = THREEAudioContext.getContext();

    if (audioContext.state === "suspended") {
      audioContext.resume();
    }
  }

  private play({ detail }: IPlaySFXEvent): void {
    const { key, layer } = detail;
    if (this.sfx.has(key)) {
      if (layer) {
        if (this.layerStates[layer]) {
          // audio is alredy playing on this layer
          return;
        }
        this.layerStates[layer] = true;
      }
      const audio = this.sfx.get(key);
      if (layer) {
        setTimeout(() => {
          this.layerStates[layer] = false;
        }, audio.buffer.duration * 1000);
      }
      audio.play();
    } else {
      console.warn(`Audio not found for ${detail}`);
    }
  }
}

export default UISfx;
