import {
  Object3D,
  Vector3,
  DynamicDrawUsage,
  MeshBasicMaterial,
  InstancedMesh,
  Mesh,
  LOD,
  PlaneBufferGeometry,
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { app } from "../../client";

const tmp = new Object3D();

const EASE = 0.01;

const MAX_GHOSTS = 6;

// use a LOD to hide the drones when they get too close to camera
class Ghost extends LOD {
  public targetPosition = new Vector3();
  private rotors: Object3D[];

  constructor(id: string, object: Object3D) {
    super();
    this.name = id;

    // object.traverse(child => {
    //   if (child instanceof Mesh) {
    //     child.material.transparent = true;
    //     child.material.opacity = 0.5;
    //   }
    // });

    this.addLevel(object, 1);
    this.addLevel(
      new Mesh(
        new PlaneBufferGeometry(1, 1, 1, 1),
        new MeshBasicMaterial({ visible: false }),
      ),
      0,
    );

    this.rotors = this.getObjectByName("MR_LL_04_RotorGroup").children;
  }

  public updateRotors(delta: number): void {
    this.rotors.forEach(rotor => (rotor.rotation.y += delta * 5));
  }
}

class Ghosts extends Object3D {
  private droneObject?: Object3D;

  constructor(droneObject: Object3D) {
    super();
    this.droneObject = droneObject;
    this.droneObject.scale.set(0.7, 0.7, 0.7);
    this.droneObject.parent.remove(this.droneObject);
  }

  public update(delta: number): void {
    this.children.forEach(ghost => {
      ghost.position.x += (ghost.targetPosition.x - ghost.position.x) * EASE;
      ghost.position.y += (ghost.targetPosition.y - ghost.position.y) * EASE;
      ghost.position.z += (ghost.targetPosition.z - ghost.position.z) * EASE;
      ghost.updateRotors(delta);
    });
  }

  public updatePositions(presences): void {
    if (!this.droneObject) return;

    presences.forEach(({ id, position }) => {
      const found = this.getObjectByName(id);
      if (found) {
        found.targetPosition.copy(position);
      } else if (this.children.length < MAX_GHOSTS) {
        this.add(new Ghost(id, this.droneObject.clone()));
      }
    });

    const toRemove = this.children.filter(({ name }) => {
      return !presences.find(({ id }) => id === name);
    });
    toRemove.forEach(ghost => this.remove(ghost));
  }
}

export default Ghosts;
