import { Frustum, Matrix4, Object3D, PerspectiveCamera, Vector3 } from "three";

type TProps = {
  text: string;
  camera: PerspectiveCamera;
  fontFamily?: string;
  fontSize?: string;
  color?: string;
  textShadow?: string;
  maxDistance?: number;
};

class TextLabel extends Object3D {
  private spanEl = document.createElement("span");
  private camera: PerspectiveCamera;
  private worldPositionVector = new Vector3();
  private frustum = new Frustum();
  private projScreenMatrix = new Matrix4();
  private maxDistance: number;

  constructor({
    text,
    camera,
    fontFamily = "sans-serif",
    fontSize = "1rem",
    color = "#ffffff",
    textShadow = "1px 1px 2px #000000",
    maxDistance = 30,
  }: TProps) {
    super();
    this.maxDistance = maxDistance;
    this.camera = camera;
    this.spanEl.innerHTML = text;
    this.spanEl.style.fontFamily = fontFamily;
    this.spanEl.style.fontSize = fontSize;
    this.spanEl.style.color = color;
    this.spanEl.style.textShadow = textShadow;
    this.spanEl.style.zIndex = "999";
    this.spanEl.style.position = "fixed";
    this.spanEl.style.whiteSpace = "nowrap";

    this.update();
    document.body.appendChild(this.spanEl);
  }

  private get inFrustum(): boolean {
    this.camera.updateMatrix(); // make sure camera's local matrix is updated
    this.camera.updateMatrixWorld(); // make sure camera's world matrix is updated

    const worldPosition = this.getWorldPosition(this.worldPositionVector);
    this.projScreenMatrix.multiplyMatrices(
      this.camera.projectionMatrix,
      this.camera.matrixWorldInverse,
    );
    this.frustum.setFromProjectionMatrix(this.projScreenMatrix);
    return this.frustum.containsPoint(worldPosition);
  }

  private get xyCoords(): Vector3 {
    const worldPosition = this.getWorldPosition(this.worldPositionVector);
    worldPosition.project(this.camera);
    worldPosition.x = ((worldPosition.x + 1) / 2) * window.innerWidth;
    worldPosition.y = (-(worldPosition.y - 1) / 2) * window.innerHeight;
    return worldPosition;
  }

  private get distance(): number {
    const worldPosition = this.getWorldPosition(this.worldPositionVector);
    return worldPosition.distanceTo(this.camera.position);
  }

  public update(): void {
    const { x, y } = this.xyCoords;
    this.spanEl.style.left = `${x}px`;
    this.spanEl.style.top = `${y}px`;
    this.spanEl.style.visibility =
      this.inFrustum && this.distance < this.maxDistance ? "visible" : "hidden";
  }

  public dispose(): void {
    document.body.removeChild(this.spanEl);
  }
}

export default TextLabel;
