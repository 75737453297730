import Hls from "hls.js";
import NetworkSpeed from "network-speed";
import MobileDetect from "mobile-detect";
import WebGLView from "./scripts/WebGLView";
import MainScene from "./scripts/scene/MainScene";
import UIController from "./scripts/ui/UIController";
import SocketsConnectionController from "./scripts/SocketsConnectionController";
import VideoHLS from "./scripts/VideoHLS";
import { UIEvents } from "./scripts/util/Events";

const video = document.createElement("video");
const md = new MobileDetect(window.navigator.userAgent);
let networkEffectiveType = navigator.connection?.effectiveType;

export default class MREurosApp {
  public webGlView: WebGLView;
  public socketsConnectionController?: SocketsConnectionController;
  public cmdPressed = false;
  public shiftPressed = false;

  static async getNetworkEffectiveType(): Promise<
    "slow-2g" | "2g" | "3g" | "4g"
  > {
    if (!networkEffectiveType) {
      try {
        const testNetworkSpeed = new NetworkSpeed();
        const baseUrl = "https://eu.httpbin.org/stream-bytes/500000";
        const fileSizeInBytes = 500000;
        const speed = await testNetworkSpeed.checkDownloadSpeed(
          baseUrl,
          fileSizeInBytes,
        );
        const kbps = parseFloat(speed.kbps);
        if (kbps <= 50) networkEffectiveType = "slow-2g";
        if (kbps <= 70) networkEffectiveType = "2g";
        if (kbps <= 700) networkEffectiveType = "3g";
        networkEffectiveType = "4g";
        console.info(speed);
      } catch (error) {
        networkEffectiveType = "4g";
      }
    }
    return networkEffectiveType;
  }

  static get isMobile(): boolean {
    return !!md.mobile();
  }

  static get isIPad(): boolean {
    return (
      navigator.userAgent.indexOf("iPad") > -1 ||
      (navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform))
    );
  }

  static get deviceGrade(): "low" | "medium" | "high" | "unknown" {
    if ("deviceMemory" in navigator && "hardwareConcurrency" in navigator) {
      if (navigator.hardwareConcurrency >= 4 && navigator.deviceMemory >= 8) {
        return "high";
      }
      if (navigator.hardwareConcurrency >= 2 && navigator.deviceMemory >= 4) {
        return "medium";
      }
      return "low";
    }

    if ("hardwareConcurrency" in navigator) {
      if (navigator.hardwareConcurrency >= 4) {
        return "high";
      }
      if (navigator.hardwareConcurrency >= 2) {
        return "medium";
      }
      return "low";
    }

    return "unknown";
  }

  static get isSafari(): boolean {
    return (
      navigator.userAgent.toLowerCase().indexOf("safari") > -1 &&
      navigator.userAgent.toLowerCase().indexOf("chrome") === -1
    );
  }

  static get videoFrameCallbackSupport(): boolean {
    return "requestVideoFrameCallback" in HTMLVideoElement.prototype;
  }

  static get videoSupport(): string | boolean {
    return Hls.isSupported()
      ? "hls"
      : video.canPlayType("application/vnd.apple.mpegurl")
      ? "native"
      : false;
  }

  constructor() {
    new UIController();
    this.webGlView = new WebGLView(
      document.querySelector("canvas"),
      new MainScene(),
    );

    if (
      !MREurosApp.isIPad &&
      !MREurosApp.isSafari &&
      !MREurosApp.isMobile &&
      MREurosApp.deviceGrade !== "low" &&
      MREurosApp.deviceGrade !== "medium"
    ) {
      this.socketsConnectionController = new SocketsConnectionController();
    }

    window.addEventListener("keydown", e => {
      if (e.key.toLowerCase() === "meta") this.cmdPressed = true;
      if (e.key.toLowerCase() === "shift") this.shiftPressed = true;
    });
    window.addEventListener("keyup", e => {
      if (e.key.toLowerCase() === "meta") this.cmdPressed = false;
      if (e.key.toLowerCase() === "shift") this.shiftPressed = false;
    });
    window.addEventListener("focus", () => {
      this.cmdPressed = false;
      this.shiftPressed = false;
    });
    window.addEventListener("blur", () => {
      this.cmdPressed = false;
      this.shiftPressed = false;
    });

    const hlsVideos = [
      ...document.querySelectorAll("video[data-playback-id"),
    ] as HTMLVideoElement[];
    hlsVideos.forEach(video => new VideoHLS(video));

    window.addEventListener(
      UIEvents.Start,
      this.requestOrientationPermission.bind(this),
    );
  }

  public async init(): void {
    this.webGlView.init();
  }

  public get webGLContext(): WebGLRenderingContext | undefined {
    return this.webGlView && this.webGlView.context;
  }

  private async requestOrientationPermission() {
    if (
      typeof DeviceMotionEvent !== "undefined" &&
      typeof DeviceMotionEvent.requestPermission === "function"
    ) {
      // iOS 13+
      try {
        await DeviceOrientationEvent.requestPermission();
      } catch (error) {
        console.warn(error);
      }
    } else {
      console.warn("Orientation not supported");
    }
  }
}

export let app;

const kickIt: () => void = () => {
  console.log("kick it");
  app = new MREurosApp();
  app.init();
};

if (document.addEventListener) {
  document.addEventListener("DOMContentLoaded", kickIt);
} else {
  (window as any).attachEvent("onload", kickIt);
}
