import {
  Mesh,
  MeshBasicMaterial,
  Object3D,
  SphereBufferGeometry,
  Vector3,
} from "three";
import Bird from "./Bird";

export const BIRD_COUNT = window.innerWidth <= 1024 ? 33 : 44;
export const BIRD_HEIGHT_VARIATION = 8;
export const BIRD_SETTINGS = {
  bounds: { x: 10, y: 6, z: 10 },
  maxVel: 0.75,
  maxSteer: 0.03,
  seperation: 2,
  viewDist: 20, // how close siblings have to be for the bird to 'see' them and react to them
};

class Birds extends Object3D {
  private birdsInc = 0;
  private birds: any[] = [];
  private birdTarget: Mesh;

  constructor() {
    super();

    this.birdTarget = new Mesh(
      new SphereBufferGeometry(1, 12, 12),
      new MeshBasicMaterial({ color: 0xff0000 }),
    );
    this.birdTarget.position.y = Math.sin(this.birdsInc * 1.25) * 10 + 40;
    this.birdTarget.visible = false;
    this.add(this.birdTarget);

    for (let i = 0; i < BIRD_COUNT; i++) {
      const x =
        Math.random() * BIRD_SETTINGS.bounds.x - BIRD_SETTINGS.bounds.x / 2;
      const y =
        this.birdTarget.position.y +
        (Math.random() - 0.5) * BIRD_HEIGHT_VARIATION;
      const z =
        Math.random() * BIRD_SETTINGS.bounds.z - BIRD_SETTINGS.bounds.z / 2;
      const bird = Bird(new Vector3(x, y, z));
      this.birds.push(bird);
      this.add(bird.mesh);
    }
  }

  public update(delta: number): void {
    this.birdsInc += 0.01 * delta;
    this.birdTarget.position.y = Math.sin(this.birdsInc * 1.25) * 15 + 35;
    this.birdTarget.position.x = Math.cos(this.birdsInc * 1.8) * 5 + 5;
    this.birdTarget.position.z = Math.sin(this.birdsInc) * 40;
    this.birds.forEach(b => {
      b.applyBehaviors(this.birdTarget.position, this.birds);
      b.update(delta);
    });
  }
}
export default Birds;
