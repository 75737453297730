import { Vector3 } from "three";
import { throttle as _throttle } from "lodash";
import io from "socket.io-client";

import { app } from "../client";

export default class SocketsConnectionController {
  public socket: any;

  constructor() {
    this.emitPosition = _throttle(this.emitPosition.bind(this));

    this.socket = io(process.env.WS_URI, { transports: ["websocket"] });
    this.socket.on("presences", this.onPresences.bind(this));
  }

  public emitPosition(position: Vector3): void {
    this.socket.emit("update-position", position);
  }

  private onPresences(presences) {
    const presencesValues = Object.values(presences).map(presence =>
      JSON.parse(presence as string),
    );
    const filteredPresences = presencesValues.filter(
      ({ id, position }) =>
        id !== this.socket.id &&
        !(position.x === 0 && position.y === 0 && position.x === 0),
    );
    app.webGlView?.mainScene?.ghosts?.updatePositions(filteredPresences);
  }
}
