import { gsap } from "gsap/all";

import { UIEvents } from "../../util/Events";

export default class CreditsView {
  private el: HTMLElement;
  private innerEl: HTMLElement;
  private isVisible = false;

  constructor() {
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);

    this.el = document.querySelector("._js_credits-view");
    this.innerEl = document.querySelector("._js_credits-view__inner");

    window.addEventListener(UIEvents.ToggleCredits, this.onToggle);
  }

  private onKeyDown(e) {
    if (e.key?.toLowerCase() === "escape") {
      this.hide();
    }
  }

  private onToggle(e) {
    if (e.detail === true) {
      if (!this.isVisible) this.show();
    } else {
      if (this.isVisible) this.hide();
    }
  }

  private show() {
    window.addEventListener("keydown", this.onKeyDown);
    setTimeout(() => {
      window.addEventListener("click", this.hide);
    }, 0);
    this.isVisible = true;
    this.el.style.display = "block";
    this.innerEl.style.transition = "none";
    this.innerEl.style.transform = "translate3d(0, 0, 0)";
    gsap.killTweensOf(this.el);
    gsap.fromTo(
      this.el,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1,
        onComplete: () => {
          this.innerEl.addEventListener("transitionend", this.hide);
          this.innerEl.style.transition = "";
          this.innerEl.style.transform = "translate3d(0, -100%, 0)";
        },
      },
    );
  }

  private hide() {
    window.removeEventListener("click", this.hide);
    window.removeEventListener("keydown", this.onKeyDown);
    this.innerEl.removeEventListener("transitionend", this.hide);
    this.isVisible = false;
    gsap.killTweensOf(this.el);
    gsap.fromTo(
      this.el,
      { opacity: 1 },
      {
        opacity: 0,
        duration: 1,
        onComplete: () => {
          this.el.style.display = "none";
          this.innerEl.style.transition = "none";
          this.innerEl.style.transform = "translate3d(0, 0, 0)";
          const onCompleteEvent = new CustomEvent(UIEvents.CreditsComplete);
          window.dispatchEvent(onCompleteEvent);
        },
      },
    );
  }
}
