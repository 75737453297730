import { UIEvents } from "../../util/Events";

export default class MuteButton {
  private el: HTMLButtonElement;
  private _isCreditsActive = false;

  constructor(el: HTMLButtonElement) {
    this.el = el;

    this.onClick = this.onClick.bind(this);

    this.el.addEventListener("click", this.onClick);
    window.addEventListener(UIEvents.CreditsComplete, () => {
      this.isCreditsActive = false;
    });
  }

  private set isCreditsActive(value: boolean) {
    this._isCreditsActive = value;
    if (value === true) {
      this.el.classList.add("is-filled");
    } else {
      this.el.classList.remove("is-filled");
    }
    const toggleCreditsEvent = new CustomEvent(UIEvents.ToggleCredits, {
      detail: value,
    });
    window.dispatchEvent(toggleCreditsEvent);
  }

  private get isCreditsActive(): boolean {
    return this._isCreditsActive;
  }

  private onClick() {
    this.isCreditsActive = !this.isCreditsActive;
  }

  public kill(): void {
    this.el.removeEventListener("click", this.onClick);
  }
}
